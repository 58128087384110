import React from "react";

export default function OfferItem({ isSelected, period, price, onSelect }) {
  const formattedPeriod = getFormattedPeriod(period);

  function getFormattedPeriod(period) {
    switch (period) {
      case "$rc_monthly":
        return "Month";
      case "$rc_annual":
        return "Year";
      case "$rc_weekly":
        return "Week";
      default:
        return period;
    }
  }

  return (
    <div
      className={`flex justify-between p-4 border bg-white rounded-xl cursor-pointer ${
        isSelected && "border-blue-500"
      }`}
      onClick={onSelect}
    >
      <div className="capitalize font-medium">{formattedPeriod + "ly"}</div>
      <div>{price + "/" + formattedPeriod}</div>
    </div>
  );
}
