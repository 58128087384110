import React, { useEffect } from "react";

import ModalView from "./ModalView";
import QRCode from "react-qr-code";
import useComponentVisible from "../../shared/hooks/useComponentVisible";

export default function PreviewCodeModal({ isOpen, handleClose, code = "" }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    useEffect(() => {
        if (isOpen) {
            setIsComponentVisible(true)
        }
        if (isComponentVisible === false) {
            handleClose()
        }
    }, [isOpen, isComponentVisible])

    return (
        <ModalView isOpen={isComponentVisible}>
            <div ref={ref} className="w-[400px] p-8 bg-white shadow rounded">
                <div className="text-center">Flash this code with the OnboardingKit app to test your onboarding</div>
                <div className="flex justify-center items-center my-5">
                    <QRCode
                        size={256}
                        style={{ height: 200 }}
                        value={code}
                    />
                </div>
                <div className="w-full bg-primary text-white p-2 rounded-md text-center cursor-pointer hover:bg-opacity-80"
                    onClick={() => setIsComponentVisible(false)}>
                    Close
                </div>
            </div>
        </ModalView>
    )
}