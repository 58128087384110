import React from "react";
import TextInput from "./controls/TextInput";
import Switch from "./controls/Switch";
import Dropdown from "./controls/Dropdown";
import AlignSwitch from "./controls/AlignSwitch";
import ColorPicker from "./controls/ColorPicker";
import ImageUploader from "./controls/ImageUploader";
import Conditions from "./controls/Conditions";
import VideoUploader from "./controls/VideoUploader";

export default function SettingsGenerator({ settings, step, index, handleUpdate, withTitle = true, prefix }) {
    const formatTitle = (title) => title.replace(prefix, "").replaceAll("_", " ")

    return (
        <div className={`${withTitle ? "space-y-4" : "space-y-2"}`}>
            {
                Object.keys(settings).map((item, idx) => (
                    <div key={'setting-item-' + item + index + idx}>
                        {
                            settings[item] === 'string' || settings[item] === 'long_string' || settings[item] === 'number' || settings[item] === 'array_string' ?
                                <TextInput
                                    title={withTitle && formatTitle(item)}
                                    placeholder={formatTitle(item)}
                                    value={step[item] || ''}
                                    onChange={(e) => handleUpdate({
                                        ...step,
                                        [item]: settings[item] === 'array_string' ?
                                            e.target.value.split(",") :
                                            settings[item] === 'number' ?
                                                parseInt(e.target.value) :
                                                e.target.value
                                    }, index)}
                                    isLongString={settings[item] === 'long_string'}
                                    isNumber={settings[item] === 'number'}
                                /> :
                                settings[item] === 'color' ?
                                    <ColorPicker
                                        title={withTitle && formatTitle(item)}
                                        value={step[item] || ''}
                                        setColor={color => handleUpdate({ ...step, [item]: color }, index)}
                                    /> :
                                    settings[item] === 'image' ?
                                        <ImageUploader
                                            title={withTitle && formatTitle(item)}
                                            index={index}
                                            item={item}
                                            image={step[item] || ''}
                                            setImage={imageName => handleUpdate({ ...step, [item]: imageName }, index)}
                                        />
                                        :
                                        settings[item] === 'video' ?
                                            <VideoUploader
                                                title={withTitle && formatTitle(item)}
                                                item={item}
                                                video={step[item] || ''}
                                                setVideo={video => handleUpdate({ ...step, [item]: video }, index)}
                                            /> :
                                            settings[item] === 'boolean' ?
                                                <Switch
                                                    title={formatTitle(item)}
                                                    value={step[item] || false}
                                                    onChange={(e) => handleUpdate({ ...step, [item]: e.target.checked }, index)}
                                                /> :
                                                Array.isArray(settings[item]) ?
                                                    <Dropdown
                                                        title={formatTitle(item)}
                                                        value={step[item] || settings[item][0]}
                                                        options={settings[item]}
                                                        onChange={(e) => handleUpdate({ ...step, [item]: e.target.value }, index)}
                                                    />
                                                    :
                                                    settings[item] === 'align' ?
                                                        <AlignSwitch
                                                            title={formatTitle(item)}
                                                            value={step[item]}
                                                            onChange={(e) => handleUpdate({ ...step, [item]: e.target.value }, index)}
                                                        />
                                                        : settings[item] === 'conditions' ?
                                                            <Conditions
                                                                operator={step["operator"] || "AND"}
                                                                conditions={step["conditions"] || []}
                                                                onOperatorChange={value => handleUpdate({ ...step, ["operator"]: value }, index)}
                                                                onConditionsChange={value => handleUpdate({ ...step, ["conditions"]: value }, index)} />
                                                            : null
                        }
                    </div>
                ))
            }
        </div>
    )
}