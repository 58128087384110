import React from "react";
import SettingsContainer from "./SettingsContainer";
import SettingsGenerator from "./SettingsGenerator";

export default function Settings({ title, icon, settings, step, handleUpdate, handleUploadImage, prefix = "" }) {
    return (
        <SettingsContainer title={title} icon={icon}>
            <SettingsGenerator
                prefix={prefix}
                settings={settings}
                step={step}
                handleUpdate={handleUpdate}
                handleUploadImage={handleUploadImage} />
        </SettingsContainer>
    )
}