import React from "react";

export default function SelectableItem({ isSelected, onSelect, customClass, ...props }) {
    return (
        <div
            className={`border border-slate-400/20 rounded-lg cursor-pointer transition-colors hover:bg-primary hover:text-white ${isSelected ? "bg-primary text-white" : "bg-white"} ${customClass}`}
            onClick={onSelect}
        >
            {props.children}
        </div>
    )
}