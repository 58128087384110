import React, { useEffect } from "react";
import { TrackableEvents, sendEvent } from "../../../../shared/helpers/TrackingManager";

import Logo from "../../../common/Logo";
import { useOnboardingContext } from "../../../../shared/hooks/useOnboarding";
import { useOnboardingLiveContext } from "../../../../shared/hooks/useOnboardingLive";
import { useUATrackingContext } from "../../../../shared/hooks/useUATracking";
import { useUserDetailsContext } from "../../../../shared/hooks/useUserDetails";

export default function PaymentSuccessfulWrapper({ userValues, settings }) {
  const { email } = useUserDetailsContext();
  const { onboardingId } = useOnboardingContext();
  const {
    adjustTrackerToken,
    campaignName,
    campaignId,
    adgroupName,
    adgroupId,
    creativeName,
    creativeId,
    fbclId,
    fbpId,
  } = useUATrackingContext();

  const downloadURL = generateDownloadURL() || settings?.ios_download_link;

  useEffect(() => {
    const onPageLoad = () => {
      sendEvent(TrackableEvents.ONB_FINISHED);
    };

    onPageLoad();
  }, []);

  const handleDownload = (event) => {
    sendEvent(TrackableEvents.APP_STORE_LINK)
  }

  function generateDownloadURL() {
    if (!adjustTrackerToken) {
      return null;
    }
    const trackerToken = adjustTrackerToken;

    let campaign,
      adgroup,
      creative,
      fbclid,
      fbpid = "";

    if (campaignName) {
      campaign = `${campaignName}${campaignId ? ` (${campaignId})` : ""}`;
    }
    if (adgroupName) {
      adgroup = `${adgroupName}${adgroupId ? ` (${adgroupId})` : ""}`;
    }
    if (creativeName) {
      creative = `${creativeName}${creativeId ? ` (${creativeId})` : ""}`;
    }
    if (fbclId) {
      fbclid = fbclId;
    }
    if (fbpId) {
      fbpid = fbpId;
    }

    const params = { campaign, adgroup, creative, fbclid, fbpid };
    const newURL =
      "https://app.adjust.com/" +
      trackerToken +
      "?" +
      Object.keys(params)
        .map((key) => key + "=" + encodeURIComponent(params[key]))
        .join("&");
    return newURL;
  }

  return (
    <div className="space-y-2 text-center">
      <div className="bg-white border rounded-lg p-4 space-y-4">
        <div className="bg-green-100 p-2 rounded-lg font-medium">
          ✅ Payment successful
        </div>
        <div className="font-semibold text-2xl">
          Welcome{settings?.app_name && " to " + settings.app_name}!
        </div>
        <Logo onboardingId={onboardingId} />
        <div>
          We are so happy to have you! Now you have full access to our amazing
          content.
        </div>
      </div>
      <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 space-y-4">
        <div className="font-semibold text-lg">To activate your account:</div>
        <div className="space-y-2">
          <div className="font-semibold">
            <span className="bg-slate-50 text-sm px-1.5 py-0.5 mr-2 rounded border">
              1
            </span>
            Download the app
          </div>
          <div>Tap the button below to dowload our app:</div>
          <a href={downloadURL} onClick={handleDownload}>
            <div className="p-3 bg-blue-600 rounded-lg text-white font-medium cursor-pointer hover:bg-blue-500">
              Download for free
            </div>
          </a>
        </div>
        <div className="space-y-2">
          <div className="font-semibold">
            <span className="bg-slate-50 text-sm px-1.5 py-0.5 mr-2 rounded border">
              2
            </span>
            Sign up
          </div>
          <div>Sign up using {email} and enjoy!</div>
        </div>
        <div className="space-y-2">
          <div className="font-semibold">
            <span className="bg-slate-50 text-sm px-1.5 py-0.5 mr-2 rounded border">
              3
            </span>
            Enjoy! 🎉
          </div>
        </div>
      </div>
    </div>
  );
}
