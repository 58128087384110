import React from "react"

export default function ProgressBar({ mainColor, secondaryColor, separatedSteps = true, stepsCount, currentStep }) {

    const progress = currentStep / stepsCount * 100

    return (
        <div>
            {
                separatedSteps ?
                    <div className="h-1 flex">
                        {
                            [...Array(stepsCount).keys()].map((_, i) =>
                                <div className="h-full w-full rounded-full mx-px" style={{ backgroundColor: i < currentStep ? mainColor : secondaryColor }} />
                            )
                        }
                    </div>
                    :
                    <div className="h-1 rounded-full" style={{ backgroundColor: secondaryColor }}>
                        <div className="h-full rounded-full transition-all duration-500" style={{ width: progress + "%", backgroundColor: mainColor }} />
                    </div>
            }
        </div>
    )
}