import React from "react"
import EditableText from "./EditableText"
import ImageContainer from "./ImageContainer"

export default function Content({
    title,
    description,
    updateText = () => { },
    image,
    imagePosition,
    imageSize,
    titleFontSize,
    bodyFontSize,
    backgroundColor,
    isEditable = false
}) {
    return (
        <div className="space-y-2">
            {image && imagePosition === "above_title" && <div className="mb-3"><ImageContainer image={image} size={imageSize} /></div>}

            <EditableText
                isEditable={isEditable}
                text={title}
                placeholder="Type title here..."
                setText={value => updateText("title", value)}
                fontSize={titleFontSize}
                backgroundColor={backgroundColor}
                className="font-semibold text-center break-words"
            />

            {image && imagePosition === "below_title" && <div className="mb-3"><ImageContainer image={image} size={imageSize} /></div>}

            <EditableText
                isEditable={isEditable}
                text={description}
                placeholder="Type description here..."
                setText={value => updateText("description", value)}
                fontSize={bodyFontSize}
                backgroundColor={backgroundColor}
                className="opacity-70 text-center break-words"
            />

            {image && imagePosition === "below_description" && <div className="mb-3"><ImageContainer image={image} size={imageSize} /></div>}
        </div>
    )
}