import React from "react"

export default function TextInput({
    isEditable = true,
    placeholder,
    mainColor,
    value,
    type = "text",
    onChange
}) {
    return (
        <div className="mt-4 text-center">
            {
                isEditable ?
                    <div className="text-3xl">
                        <span className="animate-pulse" style={{ color: mainColor }}>|</span>
                        <span className="opacity-30">{placeholder}</span>
                    </div>
                    :
                    <div>
                        <input
                            type={type}
                            className="bg-black/5 border rounded-lg text-lg w-full px-4 py-2.5"
                            style={{ outlineColor: mainColor }}
                            placeholder={placeholder}
                            value={value && value}
                            onChange={e => onChange(e.target.value)}
                        />
                    </div>
            }
        </div>
    )
}