import React from "react";

export default function CustomView({ name }) {
    return (
        <div className="h-full pb-2">
            <div className="h-full flex border border-2 border-dashed rounded-lg items-center justify-center">
                <div className="text-gray-500 font-mono">{name}</div>
            </div>
        </div>
    )
}