import ImageContainer from "../preview/content/ImageContainer";
import React from "react";
import useOnboardingCommon from "../../shared/hooks/useOnboardingCommon";

export default function Logo({ onboardingId }) {
  const { settings } = useOnboardingCommon({ onboardingId });

  return (
    <ImageContainer
      image={settings.app_logo}
      size="small"
      rounded={true}
      withImageName={false}
    />
  );
}
