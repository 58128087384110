import React from "react";
import CustomView from "../../content/CustomView";

export default function CustomWrapper({ step }) {
    return (
        <CustomView
            name={step.custom_name}
            mode={step.custom_step_type} />
    )
}
