import React from "react";

export default function TextInput({
    title,
    value,
    placeholder,
    isNumber = false,
    isLongString = false,
    onChange
}) {
    return (
        <div className={`flex flex-1 text-sm ${isNumber ? "justify-between items-center" : "flex-col"}`}>
            {title && <label className={`capitalize ${!isNumber && "mb-1"}`}>{title}</label>}
            {
                isLongString ?
                    <textarea
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        className="resize-y placeholder:italic placeholder:text-slate-400 bg-white w-full border rounded-md py-2 px-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                    /> :
                    <input
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        className={`bg-white py-1 px-2 border rounded focus:outline-none placeholder:italic placeholder:text-slate-400 ${isNumber && "w-16"}`}
                        type={isNumber ? "number" : undefined}
                    />
            }
        </div>
    )
}