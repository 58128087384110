import React from "react"
import ImageContainer from "./ImageContainer"
import EditableText from "./EditableText"
import { addAlpha } from "../../../shared/helpers/Colors"

export default function SelectOptions({
    isEditable = true,
    options,
    columns = 1,
    handleOptionTextUpdate,
    backgroundColor,
    borderRadius,
    borderWidth,
    borderColor,
    mainColor,
    alignment,
    fontSize,
    iconsPosition = "left",
    iconsSize = 30,
    verticalPadding = 12,
    displayDescOnSelect,
    isMultiSelect,
    isDirectContinue = false,
    selectedOptions = [],
    handleUpdateSelectedOptions = () => { },
    handleNextStep = () => { }
}) {
    const getStyle = (option) => ({
        backgroundColor: selectedOptions.includes(option) ? addAlpha(mainColor, 0.07) : backgroundColor,
        borderRadius,
        borderWidth,
        borderColor: selectedOptions.includes(option) ? mainColor : borderColor,
        fontSize,
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding
    })

    const renderIcon = (icon, position) => (
        icon && <div className="aspect-square" style={{ height: iconsSize }}>
            {iconsPosition === position && <ImageContainer image={icon} withImageName={false} />}
        </div>
    )

    function handleSelectOption(value) {
        if (isEditable) return
        if (isMultiSelect) {
            handleUpdateSelectedOptions(selectedOptions.includes(value) ? selectedOptions.filter(e => e !== value) : [...selectedOptions, value])
        } else {
            handleUpdateSelectedOptions([value])
        }
        if (isDirectContinue) {
            handleNextStep()
        }
    }

    return (
        <div className="grid gap-2" style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}>
            {options.map((option, index) => (
                <div
                    key={index}
                    style={getStyle(option.title)}
                    className={`px-4 flex flex-row space-x-2 ${!isEditable && "cursor-pointer hover:opacity-70"}`}
                    onClick={() => handleSelectOption(option.title)}
                >

                    {(iconsPosition === "left" || alignment === "center") &&
                        renderIcon(option.icon, "left")}

                    <div className={`w-full flex flex-col justify-center ${alignment === "center" && "items-center text-center"}`}>
                        <EditableText
                            text={option.title}
                            placeholder="Title"
                            setText={value => handleOptionTextUpdate(index, "title", value)}
                            className="font-medium"
                            fontSize={fontSize}
                            backgroundColor={backgroundColor}
                            isEditable={isEditable} />
                        {!displayDescOnSelect && option.description && option.description !== "" &&
                            <EditableText
                                text={option.description}
                                setText={value => handleOptionTextUpdate(index, "description", value)}
                                className="opacity-70"
                                fontSize={fontSize - 2}
                                backgroundColor={backgroundColor}
                                isEditable={isEditable} />
                        }
                    </div>

                    {renderIcon(option.icon, "right")}
                </div>
            ))
            }
        </div >
    )
}