import React from "react";

export default function Link({
  title,
  icon,
  url,
  style = "standard",
  openNewTab = false,
}) {
  const customClass = {
    standard: "bg-slate-50/20 border hover:bg-slate-100",
    danger: "bg-red-500 text-white hover:bg-red-400",
    secondary:
      "border border-dashed font-normal border-gray-300 text-gray-400 hover:border-gray-400 hover:text-gray-500",
    secondary_danger:
      "border border-dashed font-normal border-red-600 text-red-600 opacity-30 hover:opacity-100",
  };

  return (
    <a
      className={`flex-1 flex flex-row h-full text-sm justify-center items-center cursor-pointer rounded px-3 py-2 space-x-1 font-medium text-center transition-colors ${customClass[style]}`}
      href={url}
      rel="noopener noreferrer"
      target={`${openNewTab} ? "_blank" : "_self"`}
    >
      {icon && <span className="opacity-80">{icon}</span>}
      {title && <span>{title}</span>}
    </a>
  );
}
