import React from "react";

export default function Status({ text, type = "neutral" }) {
    const customClass = {
        neutral: "bg-gray-500 text-gray-800",
        success: "bg-green-500 text-green-800",
        alert: "bg-yellow-500 text-yellow-800",
        critical: "bg-red-500 text-red-800",
        info: "bg-blue-500 text-blue-800"
    }

    return (
        <div className="bg-white rounded-full overflow-hidden">
            <span className={`inline-flex items-center bg-opacity-20 text-xs font-medium px-2.5 py-0.5 ${customClass[type]}`}>
                <span className={`w-2 h-2 animate-pulse me-1 rounded-full ${customClass[type]}`} />
                {text}
            </span>
        </div>
    )
}