import React from 'react';

export default function ContainerLayout({ isPhone, backgroundColor, children }) {
    const baseLayout = "flex flex-col items-center overflow-y-auto";
    const phoneLayout = `h-[650px] w-[300px] rounded-[42px] outline outline-[12px] m-auto overflow-hidden relative ${baseLayout}`
    const desktopLayout = `m-5 rounded-lg shadow-lg flex-1 ${baseLayout}`

    return (
        <div
            className={isPhone ? phoneLayout : desktopLayout}
            style={{ backgroundColor }}>

            {/* NOTCH */}
            {isPhone && (
                <div className="absolute top-[10px] z-50 h-[30px] w-[95px] bg-black rounded-full" />
            )}


            {/* CONTENT */}
            <div className={`flex flex-1 flex-col justify-center items-center w-full ${isPhone && "pt-10"}`}>
                {children}
            </div>

        </div>
    );
}