import React from "react";
import { FaDesktop, FaMobile } from "react-icons/fa";

const modes = [
    { name: "phone", icon: <FaMobile /> },
    { name: "web", icon: <FaDesktop /> }
]

export default function PhoneWebToggle({ mode = "phone", setMode = () => { } }) {
    return (
        <div className="grid grid-cols-2 bg-white rounded-lg shadow">
            {
                modes.map((item) => (
                    <div
                        key={"phonewebtoggle-" + item.name}
                        className={`p-2 rounded-lg border border-2 border-white cursor-pointer ${mode === item.name ? "bg-gray-600 text-white hover:bg-gray-500" : "text-gray-500"}`}
                        onClick={() => setMode(item.name)}>
                        {item.icon}
                    </div>
                ))
            }
        </div>
    )
}