import React, { createContext, useContext } from "react";

import useOnboardingCommon from "./useOnboardingCommon";

const OnboardingContext = createContext()

export const OnboardingProvider = ({ onboardingId, children }) => {
    const {
        steps,
        settings,
        selectedStep,
        setSelectedStep,
        selectedStepIndex,
        stepsCount
    } = useOnboardingCommon({ onboardingId })

    function handleNextStep() {
        setSelectedStep(steps[Math.min(selectedStepIndex + 1, stepsCount - 1)])
    }

    function handlePreviousStep() {
        setSelectedStep(steps[Math.max(selectedStepIndex - 1, 0)])
    }

    return (
        <OnboardingContext.Provider
            value={{
                onboardingId,
                steps,
                settings,
                selectedStep,
                setSelectedStep,
                selectedStepIndex,
                stepsCount,
                handleNextStep,
                handlePreviousStep,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};

export const useOnboardingContext = () => {
    return useContext(OnboardingContext)
};