import React, { useState } from 'react';

import ContainerLayout from '../../preview/layout/ContainerLayout'
import ContentGenerator from '../../preview/layout/ContentGenerator';
import PhoneWebToggle from '../../preview/PhoneWebToggle';
import TopBar from '../../preview/content/TopBar'
import { set } from 'lodash';
import { useOnboardingConfigContext } from '../../../shared/hooks/useOnboardingConfig';

export default function CenterPanel() {
    const { step, style, selectedStep, stepsCount, selectedStepIndex, handleUpdateStep } = useOnboardingConfigContext()
    const [previewMode, setPreviewMode] = useState("phone")

    function handleUpdateText(property, newValue) {
        const updatedStep = set(selectedStep, property, newValue);
        handleUpdateStep(updatedStep);
    }

    return (
        <div className="flex flex-1 bg-gray-100 overflow-y-auto relative">
            {
                selectedStep &&
                <div className="flex flex-1 flex-col">
                    <ContainerLayout isPhone={previewMode === "phone"} backgroundColor={style.background_color}>
                        {
                            step?.type === "custom" && step?.custom_step_type === "fullScreen" && step.type !== "video" ? null :
                                <TopBar
                                    progressBarEnabled={selectedStep?.progress_bar_enabled}
                                    backEnabled={selectedStep?.back_enabled}
                                    skipEnabled={selectedStep?.skip_enabled}
                                    skipTitle={selectedStep?.skip_button_title}
                                    stepsCount={stepsCount}
                                    currentStep={selectedStepIndex}
                                    separatedSteps={style?.progress_bar_separated_steps}
                                    backgroundColor={style?.background_color}
                                    mainColor={style?.main_color}
                                    secondaryColor={style?.secondary_color} />
                        }
                        <div className="h-full w-full max-w-[420px]">
                            <ContentGenerator
                                step={selectedStep}
                                handleUpdateText={handleUpdateText}
                                style={style}
                                stepsCount={stepsCount}
                                currentStepIndex={selectedStepIndex} />
                        </div>
                    </ContainerLayout>
                </div>
            }

            <div className="absolute bottom-2 right-2">
                <PhoneWebToggle mode={previewMode} setMode={setPreviewMode} />
            </div>
        </div >
    )
}