import { ref, getDownloadURL, listAll } from 'firebase/storage';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export async function downloadConfigFolder(storage, onboardingId, style, steps, name) {
    const zip = new JSZip();
    const folderRef = ref(storage, `onboardings/${onboardingId}/images`);

    try {
        const res = await listAll(folderRef);
        const imagePromises = res.items.map(async (fileRef) => {
            const url = await getDownloadURL(fileRef);
            const response = await fetch(url);
            const blob = await response.blob();
            const filename = fileRef.name;

            //iOS
            const iosImageFolder = zip.folder(`ios/Assets.xcassets/${filename}.imageset`);
            iosImageFolder.file(filename, blob);
            const contentsJson = generateImageContensJson(filename)
            iosImageFolder.file("Contents.json", JSON.stringify(contentsJson, null, 2));

            //web
            const webImageFolder = zip.folder(`web/images`);
            webImageFolder.file(filename, blob);
        });

        await Promise.all(imagePromises);

        const configJson = generateOnboardingConfigJson(style, steps)
        zip.file("config.json", JSON.stringify(configJson, null, 2));

        const zipContent = await zip.generateAsync({ type: 'blob' });
        saveAs(zipContent, name + '.zip');
    } catch (error) {
        console.error(error);
    }
}

function generateImageContensJson(filename) {
    return {
        images: [
            { idiom: "universal", scale: "1x" },
            { idiom: "universal", scale: "2x" },
            { filename, idiom: "universal", scale: "3x" }
        ],
        info: { author: "xcode", version: 1 }
    };
}

function generateOnboardingConfigJson(style, steps) {
    return {
        'style': style,
        'steps': steps
    };
}
