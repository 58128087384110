import React from "react";
import { ChromePicker } from 'react-color';
import useComponentVisible from "../../../shared/hooks/useComponentVisible";

export default function ColorPicker({ title, value, setColor }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return (
        <div className="flex flex-1 justify-between items-center">
            {title && <label className="text-sm capitalize">{title}</label>}
            <div className="w-28 flex flex-row items-center rounded border pl-1 bg-white">
                <div
                    onClick={() => setIsComponentVisible(true)}
                    className={`relative flex h-5 aspect-square rounded border border-gray-200 cursor-pointer`}
                    style={{ backgroundColor: value }} >
                    {
                        isComponentVisible &&
                        <div ref={ref} className="absolute top-0 right-0 z-50">
                            <ChromePicker color={value} onChange={e => setColor(e.hex)} />
                        </div>
                    }
                </div>
                <input
                    value={value}
                    onChange={e => setColor(e.target.value)}
                    placeholder={title}
                    className="w-full py-1 ml-1.5 text-sm rounded outline-none"
                />
            </div>
        </div>
    )
}
