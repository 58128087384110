import React, { useState, useRef } from "react";
import { getTextColorForBackground } from "../../../shared/helpers/Colors"

export default function EditableText({ text = "", setText = () => { }, placeholder = "", className = "", fontSize, backgroundColor, isEditable = true }) {
    const [isEditing, setIsEditing] = useState(false)
    const editableRef = useRef(null)
    const textColor = getTextColorForBackground(backgroundColor)

    const handleBlur = () => {
        setIsEditing(false)
        const newText = editableRef.current?.innerText || ""
        setText(newText)
    }

    const formatText = (text) => {
        return text
            // Replace **text** with <span className="font-bold">text</span>
            .replace(/\*\*(.*?)\*\*/g, '<span className="font-bold">$1</span>')
            // Replace *text* with <span className="italic">text</span>
            .replace(/\*(.*?)\*/g, '<span className="italic">$1</span>')
            // Replace ~~text~~ with <span className="line-through">text</span>
            .replace(/~(.*?)~/g, '<span className="line-through">$1</span>')
            // Replace line break
            .replace(/\n/g, '<br>')
            // Format variables
            .replace(/\[(.*?)\]/g, `<span className="bg-yellow-100 rounded p-px">$1</span>`)
    }

    return (
        <div
            ref={editableRef}
            contentEditable={isEditable}
            onFocus={() => setIsEditing(true)}
            onBlur={handleBlur}
            className={className}
            style={{ fontSize: fontSize, outline: 0, color: textColor }}
            dangerouslySetInnerHTML={{ __html: isEditing ? text : formatText(text) }}
            placeholder={placeholder}
        />
    );
}