import React from "react";
import ContinueButton from "../../content/ContinueButton";

export default function ContinueButtonWrapper({
    step = [],
    style,
    isEditMode,
    userValues,
    handleNextStep,
    handleUpdateText,
    title = "Continue"
}) {

    if (step?.type === "loading" || step?.type === "paywall" || step?.type === "signup" || step?.type === "download" || step?.type === "payment_successful" || (step?.type === "custom" && step?.custom_step_type === "fullScreen") || (step?.direct_continue)) return
    else return (
        <ContinueButton
            isEditable={isEditMode}
            isEnabled={step.type !== 'select' || (userValues[step.property]?.length > 0)}
            title={step.continue_button_title || title}
            backgroundColor={style.main_color}
            borderRadius={parseInt(style.button_corner_radius)}
            fontSize={parseInt(style.body_font_size)}
            setTitle={value => handleUpdateText("continue_button_title", value)}
            onClick={handleNextStep} />
    )
}