import ImagePreview from "./ImagePreview"
import React from "react"

export default function ImageContainer({ image, size, withImageName, rounded = false }) {
    const width = typeof size === 'number' ? size :
        size === "small" ? "50%" :
            size === "medium" ? "66%" :
                "100%"

    return (
        <div style={{ width: width, maxWidth: width }} className="m-auto aspect-square flex justify-center items-center">
            <ImagePreview image={image} width={width} withImageName={withImageName} rounded={rounded} />
        </div>
    )
}