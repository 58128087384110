import React from "react";
import ActionMenu from "../common/ActionMenu";
import SelectableItem from "../common/SelectableItem";
import { FaRandom } from "react-icons/fa";

export default function StepItem({ isSelected, isConditional, onSelect, index, type, property, handleDuplicateStep, handleDeleteStep }) {
    return (
        < SelectableItem isSelected={isSelected} onSelect={onSelect} >

            <div className="w-full flex flex-row flex-1 justify-between items-center text-sm" onMouseDown={onSelect}>
                <div className="flex flex-row items-center w-full border-r border-gray-400/20">
                    <div className="flex text-xs flex-row px-2 py-2 w-full justify-between items-center">
                        <div className="flex flex-row items-center space-x-2">
                            <div className="inline-flex space-x-1">
                                <div className="w-5 h-5 flex font-medium justify-center items-center rounded bg-gray-300/20 border border-gray-400/20">{index}</div>
                                {isConditional && <div className="w-5 h-5 flex font-medium justify-center items-center rounded bg-yellow-300/20 border text-yellow-600 border-yellow-400/20">{<FaRandom />}</div>}
                            </div>
                            <div className="capitalize">{type}</div>
                        </div>
                        <div className="opacity-50 font-mono">{property}</div>
                    </div>
                </div>

                <div>
                    <ActionMenu actions={[
                        {
                            title: "Duplicate",
                            onClick: handleDuplicateStep
                        },
                        {
                            title: "Delete",
                            onClick: handleDeleteStep,
                            type: "danger"
                        }
                    ]} />
                </div>
            </div >
        </SelectableItem >

    )
}