import React, { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";

import ContinueButton from "./ContinueButton";
import EditableText from "./EditableText";
import { storage } from "../../../shared/helpers/Firebase";
import { useOnboardingContext } from "../../../shared/hooks/useOnboarding";

export default function VideoContent({ step, style, handleUpdateText }) {
    const { onboardingId } = useOnboardingContext();
    const badge_text = step.badge_text
    const badge_color = step.badge_color || style.main_color
    const title = step.title
    const titleFontSize = parseInt(style.title_font_size)
    const description = step.description
    const bodyFontSize = parseInt(style.body_font_size)
    const contentBackgroundColor = step.background_color || style.background_color || "red"
    const gradientStyle = {
        backgroundImage: `linear-gradient(to top, ${contentBackgroundColor} 75%, transparent)`,
    }
    const video = step.video
    const [videoUrl, setVideoUrl] = useState('')

    useEffect(() => {
        getVideoUrl(video)
    }, [video])

    async function getVideoUrl(video) {
        if (video) {
            try {
                const videoRef = ref(storage, `onboardings/${onboardingId}/videos/${video}`)
                const url = await getDownloadURL(videoRef)
                setVideoUrl(url)
            } catch (error) {
                console.error(error)
            }
        }
    }


    return (
        <div className="flex flex-col flex-1  justify-between relative -mt-10">
            <div></div>
            <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center items-center z-0">
                <video src={videoUrl} autoPlay controls loop muted />
            </div>
            <div className="z-10" style={gradientStyle}>
                <div className="mb-6 px-4 pt-4">
                    {
                        badge_text !== "" &&
                        <div className="inline-flex py-1 px-2 rounded-lg mb-2" style={{ backgroundColor: badge_color }}>
                            <EditableText
                                text={badge_text}
                                backgroundColor={badge_color}
                                className="font-medium text-xs"
                            />
                        </div>
                    }

                    <EditableText
                        text={title}
                        placeholder="Type title here..."
                        setText={value => handleUpdateText("title", value)}
                        fontSize={titleFontSize}
                        backgroundColor={contentBackgroundColor}
                        className="font-semibold break-words"
                    />
                    <EditableText
                        text={description}
                        placeholder="Type decription here..."
                        setText={value => handleUpdateText("description", value)}
                        fontSize={bodyFontSize}
                        backgroundColor={contentBackgroundColor}
                        className="opacity-70 break-words"
                    />
                </div>
                <ContinueButton
                    title={step.continue_button_title}
                    setTitle={value => handleUpdateText("continue_button_title", value)}
                    backgroundColor={style.main_color}
                    borderRadius={parseInt(style.button_corner_radius)}
                    fontSize={parseInt(style.body_font_size)} />
            </div>
        </div>
    )
}