export const initialStyleData = {
    background_color: "#FFFFFF",
    main_color: "#57A9C3",
    secondary_color: "#E8E8E8",
    text_color: "#000000",
    progress_bar_separated_steps: false,
    title_font: "Nunito-Bold",
    body_text_font: "Nunito-Regular",
    title_font_size: 20,
    body_font_size: 14,
    button_corner_radius: 25,
    fade_transition: true,
    transition_duration: 0.4,
    haptic_enabled: true,
    content_left_aligned: false,
    multiple_choice_left_aligned: true,
    multiple_choice_border_color: "#CCC",
    multiple_choice_border_width: 1
}