function hexToLuminance(hexColor) {
    if (!hexColor) return 1
    // Remove hash at the start if it's there
    hexColor = hexColor.replace(/^#/, '');

    // Parse r, g, b values
    let bigint = parseInt(hexColor, 16);
    let r = ((bigint >> 16) & 255) / 255;
    let g = ((bigint >> 8) & 255) / 255;
    let b = (bigint & 255) / 255;

    // Apply gamma correction to the RGB values
    r = (r <= 0.03928) ? r / 12.92 : Math.pow(((r + 0.055) / 1.055), 2.4);
    g = (g <= 0.03928) ? g / 12.92 : Math.pow(((g + 0.055) / 1.055), 2.4);
    b = (b <= 0.03928) ? b / 12.92 : Math.pow(((b + 0.055) / 1.055), 2.4);

    // Calculate luminance
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance;
}

export function getTextColorForBackground(hexColor) {
    let luminance = hexToLuminance(hexColor)
    return luminance > 0.5 ? "#000000" : "#FFFFFF"
}

export function addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}