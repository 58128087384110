import React from "react"
import { getTextColorForBackground } from "../../../shared/helpers/Colors"
import ProgressBar from "./ProgressBar"
import ImagePreview from "./ImagePreview"

export default function TopBar({
    layout = "one_line",
    progressBarEnabled,
    stepsCount,
    currentStep,
    separatedSteps,
    backEnabled,
    backTitle = "←",
    handleBack,
    skipEnabled,
    skipTitle = "Skip",
    handleSkip,
    backgroundColor,
    mainColor,
    secondaryColor,
    logo
}) {
    const backButton = backEnabled && (
        <TopBarButton
            onClick={handleBack}
            title={backTitle}
            backgroundColor={backgroundColor}
            withBackground={layout === "two_lines"}
        />)
    const progressBar = progressBarEnabled && (
        <ProgressBar
            mainColor={mainColor}
            secondaryColor={secondaryColor}
            stepsCount={stepsCount}
            currentStep={currentStep}
            separatedSteps={separatedSteps}
        />)
    const skipButton = skipEnabled && (
        <TopBarButton
            onClick={handleSkip}
            title={skipTitle}
            backgroundColor={backgroundColor}
            withBackground={layout === "two_lines"}
        />)
    const logoImage = logo && (
        <ImagePreview image={logo} />
    )

    const LayoutComponent = layout === "two_lines" ? TwoLinesLayout : OneLineLayout;

    return (
        <LayoutComponent
            backButton={backButton}
            progressBar={progressBar}
            skipButton={skipButton}
            logoImage={logoImage}
        />
    );
}


function TopBarButton({ onClick, title, backgroundColor, withBackground = false }) {
    const contrastColor = getTextColorForBackground(backgroundColor)
    const backgroundStyle = withBackground ? `${contrastColor === "#FFFFFF" ? "bg-white" : "bg-black"} bg-opacity-10 p-2.5 rounded-lg` : ""

    return (
        <div
            className="cursor-pointer text-sm font-medium hover:opacity-70"
            onClick={onClick}
            style={{ color: contrastColor }}
        >
            <div className={backgroundStyle}>
                {title}
            </div>
        </div>
    );
}

function OneLineLayout({ backButton, progressBar, skipButton }) {
    return (
        <div className="flex w-full items-center h-5 p-4">
            <div className="w-1/12">{backButton}</div>
            <div className="w-10/12">{progressBar}</div>
            <div className="w-1/12 flex justify-end">{skipButton}</div>
        </div>
    )
}

function TwoLinesLayout({ backButton, progressBar, skipButton, logoImage }) {
    return (
        <div className="flex flex-col w-full items-center p-4">
            <div className="w-full flex justify-between mb-2 h-10">
                <div className="flex flex-1">{backButton}</div>
                <div className="flex items-center justify-center w-32">{logoImage}</div>
                <div className="flex flex-1 justify-end">{skipButton}</div>
            </div>
            <div className="w-full">{progressBar}</div>
        </div>
    )
}