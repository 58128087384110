import React from "react";
import Content from "../../content/Content";

export default function DownloadWrapper({ isEditMode, step, style, settings, handleUpdateText }) {
    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-1 justify-center items-center">
                <Content
                    isEditable={isEditMode}
                    title={step.title}
                    description={step.description}
                    updateText={handleUpdateText}
                    image={step.image}
                    imagePosition={step.media_position || step.image_position || "above_title"}
                    imageSize={step.image_size}
                    titleFontSize={parseInt(style.title_font_size)}
                    bodyFontSize={parseInt(style.body_font_size)}
                    backgroundColor={style.background_color} />
            </div>

            <div className="w-full flex space-x-1 justify-center">
                {
                    settings.ios_download_link &&
                    <a href={settings.ios_download_link} className="w-1/2">
                        <img className="w-full" src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg" />
                    </a>
                }
                {
                    settings.android_download_link &&
                    <a href={settings.android_download_link} className="w-1/2">
                        <img className="w-full" src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" />
                    </a>
                }
            </div>
        </div>
    )
}