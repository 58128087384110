import React from "react";

export default function KovaleeLogo({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 528 173"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102.62 115.002H117.195V85.1231H117.56L140.637 115.002H159.949L133.107 83.0583L158.37 56.7017H139.665L117.56 81.2364H117.195L117.195 56.7017H102.62V115.002Z"
      />
      <path
        d="M162.553 85.6089C162.553 103.949 176.642 116.702 194.253 116.702C211.865 116.702 225.954 103.949 225.954 85.6089C225.954 67.2686 211.865 55.0013 194.253 55.0013C176.642 55.0013 162.553 67.2686 162.553 85.6089ZM177.371 85.6089C177.371 76.4995 182.715 66.9042 194.253 66.9042C205.792 66.9042 211.136 76.4995 211.136 85.6089C211.136 94.7183 205.792 104.556 194.253 104.556C182.715 104.556 177.371 94.7183 177.371 85.6089Z"
      />
      <path
        d="M263.688 118.564L293.649 56.7017H278.224L263.688 89.5921H263.445L247.252 56.7017H231.341L263.688 118.564Z"
      />
      <path
        d="M338.552 115.002H352.156V79.5359C352.156 67.8759 347.054 55.0013 326.163 55.0013C317.297 55.0013 308.066 58.1592 301.507 64.2321L309.038 73.0986C312.803 69.3334 318.754 66.4184 324.949 66.4184C332.236 66.4184 338.309 69.9407 338.309 77.1068V78.5643C320.09 78.5643 298.228 80.9935 298.228 98.3621C298.228 111.965 310.738 116.459 320.09 116.459C328.35 116.459 335.273 112.694 338.188 107.593H338.552V115.002ZM338.309 90.4672C338.309 99.6981 332.965 105.407 323.005 105.407C318.026 105.407 312.803 103.706 312.803 98.1191C312.803 89.4956 325.799 88.4024 335.03 88.4024H338.309V90.4672Z"
      />
      <path
        d="M369.379 115.002H383.954V23.1791H369.379V115.002Z"
      />
      <path
        d="M412.81 80.0218C413.417 71.8841 420.34 65.9326 428.964 65.9326C438.438 65.9326 442.689 72.4913 442.689 80.0218H412.81ZM457.264 86.5806C457.264 65.5682 445.361 55.0013 429.085 55.0013C411.474 55.0013 398.235 67.3901 398.235 86.2162C398.235 105.164 411.595 116.702 429.571 116.702C440.624 116.702 448.883 112.816 454.592 105.042L444.875 97.2689C441.596 101.884 436.737 104.799 429.45 104.799C420.948 104.799 413.417 98.7264 412.81 90.4672H457.142C457.264 89.1312 457.264 87.9166 457.264 86.5806Z"
      />
      <path
        d="M482.554 80.0218C483.161 71.8841 490.084 65.9326 498.708 65.9326C508.182 65.9326 512.433 72.4913 512.433 80.0218H482.554ZM527.008 86.5806C527.008 65.5682 515.105 55.0013 498.829 55.0013C481.218 55.0013 467.979 67.3901 467.979 86.2162C467.979 105.164 481.339 116.702 499.315 116.702C510.368 116.702 518.627 112.816 524.336 105.042L514.619 97.2689C511.34 101.884 506.481 104.799 499.194 104.799C490.692 104.799 483.161 98.7264 482.554 90.4672H526.886C527.008 89.1312 527.008 87.9166 527.008 86.5806Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 87.0586V87.052L9.79115 77.2608L77.2576 9.79443L87.052 0L96.8464 9.79443L116.94 29.8879V49.4768L87.052 19.5889L19.5856 87.0553L85.7527 153.222L116.94 122.035V141.624L95.5471 163.017L86.4024 172.161H85.1029L75.9582 163.017L9.79115 96.8497L0 87.0586Z"
      />
    </svg>
  );
}
