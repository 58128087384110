import React from "react"
import EditableText from "./EditableText"

export default function ContinueButton({
    title,
    borderRadius,
    backgroundColor,
    fontSize,
    isEditable = false,
    isEnabled = true,
    setTitle = () => { },
    onClick = () => { }
}) {
    const isClickable = !isEditable && isEnabled

    return (
        <div
            onClick={isClickable ? onClick && onClick : null}
            className={`p-3 font-medium text-center ${isClickable && "cursor-pointer hover:opacity-90"}`}
            style={{ backgroundColor, borderRadius, opacity: isEnabled ? 1 : 0.3 }}>
            <EditableText
                text={title}
                setText={setTitle}
                fontSize={fontSize + 2}
                backgroundColor={backgroundColor}
                isEditable={isEditable}
            />
        </div>
    )
}