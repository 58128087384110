import ContentWrapper from "./wrappers/ContentWrapper"
import ContinueButtonWrapper from "./wrappers/ContinueButtonWrapper"
import CustomWrapper from "./wrappers/CustomWrapper"
import DownloadWrapper from "./wrappers/DownloadWrapper"
import LoadingWrapper from "./wrappers/LoadingWrapper"
import PaymentSuccessfulWrapper from "./wrappers/PaymentSuccessfulWrapper"
import PaywallWrapper from "./wrappers/PaywallWrapper"
import PickerWrapper from "./wrappers/PickerWrapper"
import React from "react"
import SelectWrapper from "./wrappers/SelectWrapper"
import SignupWrapper from "./wrappers/SignupWrapper"
import TextInputWrapper from "./wrappers/TextInputWrapper"
import VideoWrapper from "./wrappers/VideoWrapper"
import WelcomeWrapper from "./wrappers/WelcomeWrapper"

const wrapperComponents = {
    welcome: WelcomeWrapper,
    select: SelectWrapper,
    text_input: TextInputWrapper,
    picker: PickerWrapper,
    video: VideoWrapper,
    loading: LoadingWrapper,
    custom: CustomWrapper,
    download: DownloadWrapper,
    paywall: PaywallWrapper,
    signup: SignupWrapper,
    payment_successful: PaymentSuccessfulWrapper,
    default: ContentWrapper,
};

export default function ContentGenerator({
    isEditMode = true,
    step,
    style,
    settings,
    userValues = [],
    handleUpdateText = () => { },
    handleNextStep = () => { },
    handleUpdateProperty = () => { }
}) {
    const WrapperComponent = wrapperComponents[step.type] || wrapperComponents.default

    const commonProps = {
        isEditMode,
        step,
        style,
        settings,
        userValues,
        handleUpdateText,
        handleUpdateProperty,
        handleNextStep,
    };

    return (
        <div className="flex flex-col h-full px-4 pb-6">
            <div className={`flex flex-1 flex-col pb-2 ${["select", "text_input", "picker"].includes(step.type) ? "justify-start" : "justify-center"}`}>
                <WrapperComponent  {...commonProps} />
            </div>
            <ContinueButtonWrapper  {...commonProps} />
        </div>
    )
}