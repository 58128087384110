import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";

export default function ErrorStatuts({ title = "", errors }) {
    return (
        <div className="">
            {
                errors.length > 0 && (
                    <div className="p-4 mt-2 text-red-700 border border-red-200 rounded-lg bg-red-50 space-y-2">
                        <div className="flex items-center space-x-1 text-sm">
                            <FaExclamationTriangle />
                            <h3 className="font-medium">{title}</h3>
                        </div>
                        <div className="text-xs">
                            <ul className="list-disc ml-4">
                                {
                                    errors.map(error => (
                                        <li key={"error-" + error.property}>
                                            {error.property}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                )
            }
        </div>
    )
}