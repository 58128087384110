import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../helpers/AuthFirebase"
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";


const defaultContext = {
  user: null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  status: "loading",
  error: null
}

const AuthContext = createContext(defaultContext)

function sleep(durationInMs) {
  return new Promise((resolve) => setTimeout(resolve, durationInMs))
}

export const AuthProvider = ({ children }) => {
  // const navigate = useNavigate()
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setError(null)
        setUser(user)
        setStatus("idle");
      } else {
        setStatus("idle");
      }
    })

    // // clean up
    return () => unsubscribe();
  }, []);

  const login = async (email, password) => {
    reset();

    setStatus("loading");

    await sleep(500)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredendial) => {
        const user = userCredendial.user
        setStatus("idle");
        setError(null)
        setUser(user);
      }).catch((err) => {
        setStatus("error")
        setError(err.message)
      })
  };

  const logout = async () => {
    auth.signOut()
    reset();
  };

  const reset = () => {
    setUser(null);
    setStatus("idle");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        status,
        error,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};