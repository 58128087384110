import { FaImage, FaTrash } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { storage } from "../../../shared/helpers/Firebase";
import { useOnboardingContext } from "../../../shared/hooks/useOnboarding";

export default function ImageUploader({ title = "Image", item, image, setImage }) {
    const { onboardingId } = useOnboardingContext();
    const [preview, setPreview] = useState(null);

    const types = ["image/png", "image/jpeg", "image/jpg"];

    useEffect(() => {
        getImageUrl(image)
    }, [image]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && types.includes(file.type)) {
            uploadImage(file)
            setPreview(URL.createObjectURL(file));
        }
    };

    function uploadImage(image) {
        if (image) {
            try {
                const imageRef = ref(storage, `onboardings/${onboardingId}/images/${image.name}`);
                uploadBytes(imageRef, image).then(() => setImage && setImage(image.name))
            } catch (error) {
                console.error(error);
            }
        }
    }

    function handleRemoveImage() {
        setPreview(null)
        setImage && setImage("")
    }

    async function getImageUrl(image) {
        if (image) {
            try {
                const imageRef = ref(storage, `onboardings/${onboardingId}/images/${image}`);
                const url = await getDownloadURL(imageRef)
                setPreview(url)
            } catch (error) {
                console.error(error);
            }
        }
    }

    return (
        <div className="">
            {title && <label className="text-sm capitalize" >{title}</label>}
            <div className="flex w-full text-sm mt-1 border-dashed rounded-lg items-center justify-center">
                <label
                    className="h-full w-full flex items-center justify-center cursor-pointer"
                    htmlFor="image-upload"
                >
                    {image ? (
                        <div className="flex flex-1 items-center">
                            {
                                preview ?
                                    <img src={preview} alt="Image preview" className="h-10 aspect-square border rounded object-contain" /> :
                                    <div className="h-10 aspect-square border rounded flex items-center justify-center">
                                        <FaImage />
                                    </div>
                            }
                            <span className="ml-2 opacity-50">{image}</span>
                        </div>
                    ) : (
                        <div className="flex flex-1 p-2 rounded items-center justify-center border hover:border-gray-300">
                            <div className="text-lg" >
                                <FaImage />
                            </div>
                            <span className="ml-2">{`Upload ${item || "image"}`}</span>
                        </div>
                    )}
                    <input
                        id="image-upload"
                        type="file"
                        className="hidden"
                        onChange={handleImageChange}
                        accept="image/*"
                    />
                </label>
                {image && <div className="opacity-30 text-red-500 cursor-pointer hover:opacity-100" onClick={handleRemoveImage}><FaTrash /></div>}
            </div >
        </div >
    );
}
