import Adjust from "@adjustcom/adjust-web-sdk";
import { isDevMode } from "./Dev";

export function initializeAdjust(appToken) {
  const isDev = isDevMode();
  
  Adjust.initSdk({
    appToken,
    environment: isDev ? "sandbox" : "production",
    logLevel: isDev ? "verbose" : "info",
  });
}

export function setAdjustExternalDeviceId(deviceId) {
  Adjust.addGlobalCallbackParameters([{ key: "externalDeviceId", value: deviceId }]);
}

export function trackPurchase(eventToken, revenue, currency) {
  Adjust.trackEvent({ eventToken, revenue, currency });
}