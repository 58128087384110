import React from "react";
import SettingsGenerator from "./SettingsGenerator";
import Button from "../common/Button";
import SettingsContainer from "./SettingsContainer";

export default function OptionsSettings({
    title = "",
    icon,
    settings = {},
    options = [{ title: '', description: '' }],
    path,
    handleOptionChange,
    addOption,
    removeOption
}) {
    return (
        <SettingsContainer title={title} icon={icon}>
            {options && options.map((option, index) => (
                <div className="space-y-2" key={"option-" + path + index}>
                    <SettingsGenerator
                        settings={settings}
                        step={option}
                        index={index}
                        handleUpdate={(value, index) => handleOptionChange(path, value, index)}
                        withTitle={false} />
                    <Button title="Delete" style="secondary_danger" onClick={() => removeOption(path, index)} />
                </div>
            ))}
            <Button title="+ Add" style="secondary" onClick={() => addOption(path, {})} />
        </SettingsContainer>
    )
}