import React from "react";
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";
import useComponentVisible from "../../shared/hooks/useComponentVisible";

const defaultActions = [{
    title: "Edit",
    onClick: () => { },
    type: "neutral"
}, {
    title: "Delete",
    onClick: () => { },
    type: "danger"
},]

export default function ActionMenu({ actions = defaultActions }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    function handleClick(onClick) {
        setIsComponentVisible(false)
        onClick && onClick()
    }

    return (
        <div className="relative">
            <button className="w-8 h-8 flex bg-transparent rounded justify-center items-center" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                {isComponentVisible ? <GoTriangleUp /> : <GoTriangleDown />}
            </button>
            {
                isComponentVisible &&
                <div ref={ref} className="absolute right-0 bg-white rounded shadow z-50">
                    {actions.map(action => (
                        <div onClick={() => handleClick(action.onClick)} className={`w-36 m-1 p-2 rounded cursor-pointer hover:bg-gray-100 ${action.type === "danger" ? "text-red-700" : "text-black"}`}>
                            {action.title}
                        </div>
                    ))
                    }
                </div>
            }

        </div>
    )
}