import React, { useCallback, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import ContinueButton from "./ContinueButton";
import Content from "./Content";
import { addAlpha, getTextColorForBackground } from "../../../shared/helpers/Colors";

export default function Loader({
    isEditable,
    title,
    description,
    titleFontSize,
    bodyFontSize,
    backgroundColor,
    continueButtonTitle = "Continue",
    continueButtonBorderRadius,
    mainColor,
    steps = [],
    handleUpdateText = () => { },
    handleNextStep = () => { }
}) {
    const [currentStep, setCurrentStep] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const isLoaded = currentStep === steps.length - 1 && percentage === 100
    const contrastColor = getTextColorForBackground(backgroundColor)
    const twContrastBgColor = contrastColor === "#FFFFFF" ? "bg-white" : "bg-black"
    const twContrastBorderColor = contrastColor === "#FFFFFF" ? "divide-white/10" : "divide-black/10"

    useEffect(() => {
        if (percentage < 100) {
            const timer = setTimeout(() => setPercentage(percentage + 1), 30);
            return () => clearTimeout(timer);
        } else if (currentStep < steps.length - 1) {
            const timeout = setTimeout(() => {
                setCurrentStep(currentStep + 1);
                setPercentage(0);
            }, 300)
            return () => clearTimeout(timeout);
        }
    }, [percentage, currentStep]);

    const renderStep = useCallback((step, index) => {
        const isCurrentStep = index === currentStep;
        const isStepComplete = index < currentStep || (isCurrentStep && percentage === 100);

        return isStepComplete || isCurrentStep ? (
            <div className="py-3" key={index}>
                <div className="w-full inline-flex justify-between items-center mb-1">
                    <div className="font-semibold" style={{ color: contrastColor }}>{step.title}</div>
                    {isStepComplete ? (
                        <FaCheckCircle color={mainColor} />
                    ) : (
                        <div style={{ color: addAlpha(contrastColor, 0.3) }}>{`${percentage}%`}</div>
                    )}
                </div>
                {!isStepComplete && (
                    <div className={`h-1.5 w-full rounded-full bg-opacity-10 ${twContrastBgColor}`}>
                        <div
                            className="h-full rounded-full"
                            style={{ backgroundColor: mainColor, width: `${currentStep > index ? "100%" : currentStep === index ? percentage : 0}%` }}
                        />
                    </div>
                )}
            </div>
        ) : null;
    }, [currentStep, percentage, contrastColor, mainColor, twContrastBgColor]);

    return (
        <div className="flex flex-col flex-1">
            <Content
                isEditable={isEditable}
                title={title}
                description={description}
                updateText={(property, value) => handleUpdateText(property, value)}
                titleFontSize={titleFontSize}
                bodyFontSize={bodyFontSize}
                backgroundColor={backgroundColor} />
            <div className={`flex flex-1 flex-col py-2 divide-y ${twContrastBorderColor}`}>
                {steps.map(renderStep)}
            </div>
            {isLoaded && (
                <ContinueButton
                    isEditable={isEditable}
                    title={continueButtonTitle}
                    backgroundColor={mainColor}
                    borderRadius={continueButtonBorderRadius}
                    fontSize={bodyFontSize}
                    setTitle={value => handleUpdateText("continue_button_title", value)}
                    onClick={handleNextStep}
                />
            )}
        </div >
    )
}