import React from "react";

export default function ModalView({ children, isOpen }) {

    if (!isOpen) return null

    return (
        <div className="absolute top-0 bottom-0 right-0 left-0 backdrop-blur-sm bg-gray-400/50 justify-center items-center flex z-50">
            <div>
                {children}
            </div>
        </div>
    )
}