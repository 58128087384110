import React from "react";
import Content from "../../content/Content";

export default function ContentWrapper({ isEditMode, step, style, handleUpdateText }) {
    return (
        <Content
            isEditable={isEditMode}
            title={step.title}
            description={step.description}
            updateText={handleUpdateText}
            image={step.image}
            imagePosition={step.media_position || step.image_position || "above_title"}
            imageSize={step.image_size}
            titleFontSize={parseInt(style.title_font_size)}
            bodyFontSize={parseInt(style.body_font_size)}
            backgroundColor={style.background_color} />
    )
}