import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import { db } from "../helpers/Firebase";
import { initialStyleData } from "../../data/InitialStyleData";

export default function useOnboardingCommon({ onboardingId }) {
    const docRef = doc(db, "onboardings", onboardingId);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState(null);
    const [steps, setSteps] = useState([]);
    const [style, setStyle] = useState(initialStyleData);
    const [settings, setSettings] = useState([])
    const [selectedStep, setSelectedStep] = useState(null);
    const selectedStepIndex = steps.indexOf(selectedStep);
    const stepsCount = steps.length;

    useEffect(() => {
        if (onboardingId) fetchOnboarding()
    }, [onboardingId])

    async function fetchOnboarding() {
        await getDoc(docRef).then(docSnap => {
            setIsLoading(false)
            if (docSnap.exists()) {
                const data = docSnap.data()
                setName(data.name || "")
                setSteps(data.steps || [])
                setStyle(data.style || [])
                setSettings(data.settings || {})
            }
        })
    }

    useEffect(() => {
        if (selectedStep === null && steps.length > 0) {
            setSelectedStep(steps[0]);
        }
    }, [steps, selectedStep]);

    return {
        isLoading,
        name,
        setName,
        steps,
        setSteps,
        style,
        setStyle,
        settings,
        setSettings,
        selectedStep,
        setSelectedStep,
        selectedStepIndex,
        stepsCount,
    };
}