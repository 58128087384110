import React from "react";
import ActionMenu from "../common/ActionMenu";

export default function OnboardingItem({ item, handleOpenOnboarding, handleDuplicateOnboarding, handleDeleteOnboarding }) {
    return (
        <div className="rounded shadow-sm transition-shadow hover:shadow-md my-3 bg-white flex flex-row items-center">
            <div className="flex flex-1 p-3 flex-row items-center cursor-pointer" onClick={() => handleOpenOnboarding(item.id)}>
                <div className="w-2/6">
                    <div className="text-sm">{item.name}</div>
                    <div className="text-xs text-gray-500">Created: {item.created?.toDate().toDateString()}</div>
                </div>
                <div className="w-1/6 text-sm text-gray-500">{item.creator}</div>
                <div className="w-1/6 flex">
                    <div className="flex flex-row rounded overflow-hidden border border-gray-100">
                        <div className="h-6 w-6" style={{ backgroundColor: item.style?.main_color }} />
                        <div className="h-6 w-6" style={{ backgroundColor: item.style?.secondary_color }} />
                        <div className="h-6 w-6" style={{ backgroundColor: item.style?.background_color }} />
                        <div className="h-6 w-6" style={{ backgroundColor: item.style?.text_color }} />
                    </div>
                </div>
                <div className="w-1/6 text-sm text-gray-500">{item.steps?.length || 0}</div>
                <div className="w-1/6 text-sm text-gray-500">{item.updated?.toDate().toDateString()}</div>
            </div>

            <div className="mr-3 flex">
                <ActionMenu actions={[
                    {
                        title: "Edit",
                        onClick: () => handleOpenOnboarding(item.id)
                    },
                    {
                        title: "Duplicate",
                        onClick: () => handleDuplicateOnboarding(item.id)
                    },
                    {
                        title: "Delete",
                        onClick: () => handleDeleteOnboarding(item.id),
                        type: "danger"
                    }
                ]} />
            </div>
        </div>
    )
}