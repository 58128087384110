import React from "react";
import { FaAlignCenter, FaAlignLeft } from "react-icons/fa";

export default function AlignSwitch({ title, value = false, onChange }) {

    function handlePress(newValue) {
        const event = {
            target: { newValue }
        }
        onChange(event)
    }
    return (
        <div className="flex direction-row justify-between items-center" >
            {title && <label className="text-sm capitalize">{title}</label>}
            <div className="flex">
                <div className={`p-1.5 rounded cursor-pointer ${value && "bg-gray-600 text-white"}`} onClick={() => handlePress(true)}>
                    <FaAlignLeft />
                </div>
                <div className={`p-1.5 rounded cursor-pointer ${!value && "bg-gray-600 text-white"}`} onClick={() => handlePress(false)}>
                    <FaAlignCenter />
                </div>
            </div>
        </div>
    )
}