import React, { useState } from "react";

import CenterPanel from "../components/layout/create/CenterPanel";
import LeftPanel from "../components/layout/create/LeftPanel";
import { OnboardingConfigProvider } from "../shared/hooks/useOnboardingConfig";
import { OnboardingProvider } from "../shared/hooks/useOnboarding";
import PreviewCodeModal from "../components/common/PreviewCodeModal";
import RightPanel from "../components/layout/create/RightPanel";
import StepsListModal from "../components/layout/create/StepsListModal";
import { Toaster } from "react-hot-toast";
import TopBar from "../components/layout/create/TopBar";
import { UserDetailsProvider } from "../shared/hooks/useUserDetails";
import { useParams } from "react-router-dom";

function CreatePage() {
  const params = useParams();

  const onboardingId = params?.onboardingId;
  const [editorMode, setEditorMode] = useState("content");
  const [isPreviewCodeOpen, setIsPreviewCodeOpen] = useState(false);
  const [isStepsListOpen, setIsStepsListOpen] = useState(false);

  return (
    <OnboardingProvider onboardingId={onboardingId}>
      <OnboardingConfigProvider onboardingId={onboardingId}>
        <UserDetailsProvider>
          <div className="h-screen">
            <Toaster />
            <StepsListModal
              isOpen={isStepsListOpen}
              handleClose={() => setIsStepsListOpen(false)}
            />
            <PreviewCodeModal
              isOpen={isPreviewCodeOpen}
              handleClose={() => setIsPreviewCodeOpen(false)}
              code={onboardingId}
            />
            <TopBar
              handleDisplayPreviewCode={() => setIsPreviewCodeOpen(true)}
              editorMode={editorMode}
              setEditorMode={setEditorMode}
            />
            <div className="flex h-[calc(100%-48px)]">
              <LeftPanel handleOpenStepsList={() => setIsStepsListOpen(true)} />
              <CenterPanel />
              <RightPanel editorMode={editorMode} />
            </div>
          </div>
        </UserDetailsProvider>
      </OnboardingConfigProvider>
    </OnboardingProvider>
  );
}

export default CreatePage;
