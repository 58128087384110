import React from "react";

export default function Spinner() {

    const createAnimation = (frames) => {
        const animationName = "spinner-animation";

        if (typeof window == "undefined" || !window.document) {
            return animationName;
        }

        const styleEl = document.createElement("style");
        document.head.appendChild(styleEl);
        const styleSheet = styleEl.sheet;

        const keyFrames = `
          @keyframes ${animationName} {
            ${frames}
          }
        `;

        if (styleSheet) {
            styleSheet.insertRule(keyFrames, 0);
        }

        return animationName;
    };
    const sync = createAnimation(
        `33% {transform: translateY(10px)}
        66% {transform: translateY(-10px)}
        100% {transform: translateY(0)}`
    );

    const style = (i) => {
        return {
            backgroundColor: "#14213d",
            width: 8,
            height: 8,
            margin: 2,
            borderRadius: "100%",
            display: "inline-block",
            animation: `${sync} 1s ${i * 0.07}s infinite ease-in-out`,
            animationFillMode: "both",
        };
    };
    return (
        <span>
            <span style={style(1)} />
            <span style={style(2)} />
            <span style={style(3)} />
        </span>
    )
}