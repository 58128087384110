import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDOJNTJjGCdcbsSTx36yV9KIntyVZQDiKM",
    authDomain: "coty-4ba31.firebaseapp.com",
    databaseURL: "https://coty-4ba31.firebaseio.com",
    projectId: "coty-4ba31",
    storageBucket: "coty-4ba31.appspot.com",
    messagingSenderId: "475388145391",
    appId: "1:475388145391:web:ce4b624b90a953de1df51a",
    measurementId: "G-B9BTKTBJB3",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)

// export function initializeFirebase() {
//     initializeApp(firebaseConfig);
// }

// export async function handleSignOut() {
//     // await firebase.auth().signOut();
// }
// export function onAuthStateChanged(onChange) {
//     // return firebase.auth().onAuthStateChanged(onChange);
// }

// export async function signIn(email, password) {
//     signInWithEmailAndPassword(auth, email, password)
//         .then((userCredendial) => {
//             return userCredendial.user
//         }).catch((error) => {
//             const errorCode = error.code;
//             const errorMessage = error.message;
//         })
// }