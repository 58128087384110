import React from "react"
import ContentWrapper from "./ContentWrapper"
import SelectOptions from "../../content/SelectOptions"

export default function SelectWrapper({ isEditMode, step, style, userValues, handleNextStep, handleUpdateProperty, handleUpdateText }) {
    return (
        <div className="space-y-4">
            <ContentWrapper
                isEditMode={isEditMode}
                step={step}
                style={style}
                handleUpdateText={(property, value) => handleUpdateText(property, value)}
            />
            {step.select_options && (
                <SelectOptions
                    isEditable={isEditMode}
                    options={step.select_options}
                    columns={step.columns}
                    backgroundColor={style.multiple_choice_background_color || style.secondary_color}
                    fontSize={parseInt(style.body_font_size)}
                    borderRadius={parseInt(style.multiple_choice_corner_radius || style.button_corner_radius)}
                    borderWidth={style.multiple_choice_border_width || 0}
                    borderColor={style.multiple_choice_border_color || "transparent"}
                    mainColor={style?.main_color}
                    alignment={style.multiple_choice_left_aligned ? "left" : "center"}
                    iconsPosition={step.icons_position}
                    iconsSize={step.icons_size}
                    verticalPadding={step.vertical_padding}
                    displayDescOnSelect={step.display_description_on_select}
                    isMultiSelect={step.multi_select}
                    isDirectContinue={step.direct_continue}
                    selectedOptions={userValues[step.property] || []}
                    handleOptionTextUpdate={(i, property, value) => handleUpdateText(`${"select_options"}[${i}].${property}`, value)}
                    handleUpdateSelectedOptions={values => handleUpdateProperty(step.property, values)}
                    handleNextStep={handleNextStep}
                />
            )}
        </div>
    )
}