import React from "react";
import ContentWrapper from "./ContentWrapper";
import Picker from "../../content/Picker";

export default function PickerWrapper({ isEditMode, step, style, handleUpdateText }) {
    return (
        <>
            <ContentWrapper
                isEditMode={isEditMode}
                step={step}
                style={style}
                handleUpdateText={(property, value) => handleUpdateText(property, value)}
            />
            <Picker
                mode={step.picker_mode}
                picker_options={step.picker_options}
            />
        </>
    )
}
