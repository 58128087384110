import React, { useEffect } from "react";

import ModalView from "../../common/ModalView";
import SelectableItem from "../../common/SelectableItem";
import useComponentVisible from "../../../shared/hooks/useComponentVisible";
import { useOnboardingConfigContext } from "../../../shared/hooks/useOnboardingConfig";

const isDev = process.env.NODE_ENV === "development"

const dev_steps_list = [
]

const prod_steps_list = [
    ["👋", "welcome"],
    ["ℹ️", "intermediate"],
    ["🎥", "video"],
    ["✅", "select"],
    ["⌨️", "text_input"],
    ["🗓️", "picker"],
    ["🔔", "notification"],
    ["⏳", "loading"],
    ["⚙️", "custom"],
    ["📲", "download"],
    ["👤", "signup"],
    ["💳", "paywall"]
]

const steps_list = [...prod_steps_list, ...(isDev ? dev_steps_list : [])]


export default function StepsListModal({ isOpen, handleClose }) {
    const { handleAddStep } = useOnboardingConfigContext()
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    useEffect(() => {
        if (isOpen) {
            setIsComponentVisible(true)
        }
        if (isComponentVisible === false) {
            handleClose()
        }
    }, [isOpen, isComponentVisible])

    function handleSelect(step) {
        setIsComponentVisible(false)
        handleAddStep(step)
    }

    return (
        <ModalView isOpen={isComponentVisible}>
            <div ref={ref} className="flex flex-col w-96 bg-white m-8 shadow rounded p-4">
                <div className="h-full rounded-lg overflow-hidden">
                    <div className="text-center font-medium text-lg mb-2">Select a step to add</div>
                    <div className="flex flex-row w-full text-sm">
                        {
                            <div className="w-full grid grid-cols-2 gap-2">
                                {
                                    steps_list.map(item => (
                                        <SelectableItem onSelect={() => handleSelect(item[1])} key={"steps_list_item-" + item[1]}>
                                            <div className="p-2 capitalize inline-flex items-center">
                                                <div className="text-xs p-1 mr-2 rounded bg-slate-300/20 border border-slate-400/20">{item[0]}</div>
                                                <div>{item[1]}</div>
                                            </div>
                                        </SelectableItem>)
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ModalView >
    )
}