import React, { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";

import { FaImage } from "react-icons/fa";
import { storage } from "../../../shared/helpers/Firebase";
import { useOnboardingContext } from "../../../shared/hooks/useOnboarding";

export default function ImagePreview({
  image,
  width,
  withImageName = true,
  rounded = false,
}) {
  const { onboardingId } = useOnboardingContext();
  const [imageUrl, setImageUrl] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getImageUrl(image);
  }, [image]);

  async function getImageUrl(image) {
    if (image) {
      try {
        const imageRef = ref(
          storage,
          `onboardings/${onboardingId}/images/${image}`
        );
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
        setIsLoaded(true);
      } catch (error) {
        console.error(error);
      }
    }
  }

  if (!isLoaded) return null;

  return (
    <>
      {imageUrl ? (
        <img src={imageUrl} className={rounded ? "rounded-lg" : ""} />
      ) : (
        <div
          style={{ width: width, height: width }}
          className={`bg-neutral-100 border border-neutral-200 text-neutral-400 aspect-square flex flex-col justify-center items-center`}
        >
          <FaImage className="mb-1" size={16} />
          {withImageName && (
            <span className="text-sm font-medium">{image}</span>
          )}
        </div>
      )}
    </>
  );
}
