import Button from '../../common/Button';
import DraggableList from '../../common/DraggableList';
import ErrorStatuts from '../../common/ErrorStatus';
import React from 'react';
import StepItem from '../../steps/StepItem';
import { useOnboardingConfigContext } from '../../../shared/hooks/useOnboardingConfig';

function LeftPanel({ handleOpenStepsList }) {
    const {
        isLoading,
        steps,
        setSteps,
        selectedStep,
        setSelectedStep,
        handleDuplicateStep,
        handleDeleteStep,
        errors
    } = useOnboardingConfigContext()

    if (isLoading) return null

    return (
        <div className="w-64 flex flex-col p-2 bg-gray-100 overflow-y-auto">
            <div className="mb-2">
                <Button title="+ Add a step" style="secondary" onClick={handleOpenStepsList} />
            </div>

            <div className="flex-1 overflow-y-auto">
                {
                    steps.length > 0 ?
                        <>
                            <DraggableList
                                className="space-y-1"
                                items={steps}
                                setItems={setSteps}
                                renderItem={(step, index) =>
                                    <StepItem
                                        index={index + 1}
                                        type={step.type}
                                        property={step.property}
                                        isConditional={step.conditions?.length > 0}
                                        isSelected={selectedStep === step}
                                        onSelect={() => setSelectedStep(step)}
                                        handleDuplicateStep={() => handleDuplicateStep(selectedStep)}
                                        handleDeleteStep={() => handleDeleteStep(index)}
                                    />
                                }
                            />
                            <ErrorStatuts
                                title="Missing content"
                                errors={errors && errors.filter(obj => obj.type === "missing_content")} />
                        </>
                        :
                        <span className="p-2 text-sm text-center h-full flex items-end">☝️ Click + to add a step to your onboarding</span>
                }
            </div>
        </div>
    );
}

export default LeftPanel;
