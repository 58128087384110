/* eslint-disable */

export function initializeGoogleTag(googleTagId) {
  console.log("Google Tag initialization... ");

  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagId}`;
  document.head.appendChild(script);

  const script2 = document.createElement("script");
  script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleTagId}');
    `;
  document.head.appendChild(script2);

  console.log("✅ Google Tag initialized");
}

export function trackPurchase(revenue, currency, productId) {
  gtag("event", "purchase", {
    value: revenue,
    currency: currency,
    items: [
      {
        id: productId,
        quantity: 1,
        price: revenue,
      },
    ],
  });
}

export function trackPageView(pageTitle) {
  gtag("event", "page_view", { page_title: pageTitle });
}
