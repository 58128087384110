import React from "react"

export default function PageStepper({ pages, currentPage, onSelectPage, mainColor, secondaryColor }) {
    if (!pages) return null

    return (
        <div className="flex justify-center mb-5">
            {pages.map((_, index) => (
                <div onClick={() => onSelectPage(index)}
                    className="h-3 w-3 bg-gray-100 rounded-full mx-0.5"
                    style={{ backgroundColor: index === currentPage ? mainColor : secondaryColor }} />
            ))}
        </div>
    )
}