/* eslint-disable */

export function initializeFacebookPixel(pixelID) {
  console.log("Facebook Pixel initialization... ");

  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) {
      console.error("Error initializing Facebook Pixel");
      return;
    }
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );

  fbq("init", pixelID); // Insert your Pixel ID here
  fbq("track", "PageView");

  console.log("✅ Facebook Pixel initialized");
}

export function sendFBEvent(event, eventProperties) {
  fbq("trackCustom", event, eventProperties);
}

export function trackPurchase(revenue, currency, productId) {
  fbq("track", "Purchase", {
    value: revenue,
    currency: currency,
    content_ids: [productId],
  });
}

//get fbpid from Cookie written by Facebook Pixel
export function getFbPid() {
  let fbPid = document.cookie.match(/(^|;) ?_fbp=([^;]*)(;|$)/);
  if (fbPid) {
    return fbPid[2];
  } else {
    return null;
  }
}
