import React, { useEffect, useState } from "react"
import ContentWrapper from "./ContentWrapper"
import PageStepper from "../../content/PageStepper"

export default function WelcomeWrapper({ isEditMode, step, style, handleUpdateText }) {
    const [currentWelcomePage, setCurrentWelcomePage] = useState(0)

    useEffect(() => {
        setCurrentWelcomePage(Math.min(currentWelcomePage, step.content?.length - 1))
    }, [step.content])

    const content = step.content?.length > 0 ? step.content[currentWelcomePage] : step
    return (
        <>
            <ContentWrapper
                isEditMode={isEditMode}
                step={content}
                style={style}
                handleUpdateText={(property, value) => handleUpdateText(`${"content"}[${currentWelcomePage}].${property}`, value)}
            />
            {step.content?.length > 1 && (
                <PageStepper pages={step.content}
                    currentPage={currentWelcomePage}
                    onSelectPage={setCurrentWelcomePage}
                    mainColor={style.main_color}
                    secondaryColor={style.secondary_color} />
            )
            }
        </>
    )
}