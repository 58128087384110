import React from "react";
import Loader from "../../content/Loader";

export default function LoadingWrapper({ isEditMode, step, style, handleUpdateText, handleNextStep }) {
    return (
        <Loader
            isEditable={isEditMode}
            title={step?.loading_title}
            steps={step?.loading_steps}
            titleFontSize={parseInt(style.title_font_size)}
            bodyFontSize={parseInt(style.body_font_size)}
            backgroundColor={style.background_color}
            continueButtonTitle={step.continue_button_title}
            continueButtonBorderRadius={parseInt(style.button_corner_radius)}
            mainColor={style?.main_color}
            handleUpdateText={handleUpdateText}
            handleNextStep={handleNextStep}
        />
    );
}