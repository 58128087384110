import { ErrorCode, Purchases, PurchasesError } from "@revenuecat/purchases-js";

export function initializeRevenueCat(apiKey, userId) {
  Purchases.configure(
    apiKey,
    userId
  );
}

export async function getOfferings() {
  try {
    const offerings = await Purchases.getSharedInstance().getOfferings();
    if (
      offerings.current !== null &&
      offerings.current.availablePackages.length !== 0
    ) {
      return offerings.current.availablePackages;
    }

    return [];
  } catch (e) {
    // Handle errors
    console.error(e);
    return null;
  }
}

export async function purchase(offer, onSuccess, onError) {
  try {
    const { customerInfo } =
      await Purchases.getSharedInstance().purchasePackage(offer);

    console.log("Success 🎉");
    onSuccess();
  } catch (e) {
    if (
      e instanceof PurchasesError &&
      e.errorCode === ErrorCode.UserCancelledError
    ) {
      // User cancelled the purchase process, don't do anything
      console.log("User cancelled the purchase");
    } else {
      // Handle errors
      console.error("Error: ", e);
      onError();
    }
  }
}