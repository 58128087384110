import React from "react";
import Button from "../../common/Button";
import { FaTrash } from "react-icons/fa";

export default function Conditions({ operator, conditions = [], onOperatorChange, onConditionsChange }) {
    function handleAddCondition() {
        onConditionsChange([...conditions, { property: "", comparator: "=", value: "" }])
    }

    function handleUpdateConditions(index, key, value) {
        const conditionsUpdated = [...conditions]
        conditionsUpdated[index][key] = value
        onConditionsChange(conditionsUpdated)
    }

    function handleRemoveCondition(index) {
        const conditionsUpdated = [...conditions]
        conditionsUpdated.splice(index, 1)
        onConditionsChange(conditionsUpdated)
    }

    return (
        <div className="space-y-2">
            {
                conditions && conditions.length > 0 &&
                <div className="text-xs space-y-1">
                    {
                        conditions.map((cond, index) => (
                            <div className="inline-flex flex-1 space-x-1 items-center">
                                <div className="font-medium w-24">{index === 0 ?
                                    <span>IF</span> :
                                    index === 1 ?
                                        <select
                                            className="form-select appearance-none w-full px-0 py-1 text-center border font-medium rounded outline-none"
                                            value={operator}
                                            onChange={(e) => onOperatorChange(e.target.value)}>
                                            {["AND", "OR"].map(e => <option value={e}>{e}</option>)}
                                        </select > :
                                        <span>{operator}</span>
                                }</div>
                                <input
                                    value={cond.property}
                                    onChange={e => handleUpdateConditions(index, "property", e.target.value)}
                                    placeholder="property"
                                    className="flex w-full border rounded py-1 px-1.5 outline-none"
                                />
                                <select
                                    className="form-select appearance-none p-1 border font-medium rounded outline-none"
                                    value={cond.comparator}
                                    onChange={(e) => handleUpdateConditions(index, "comparator", e.target.value)}>
                                    {["=", "≠"].map(e => <option value={e}>{e}</option>)}
                                </select >
                                <input
                                    value={cond.value}
                                    onChange={e => handleUpdateConditions(index, "value", e.target.value)}
                                    placeholder="value"
                                    className="flex w-full border rounded py-1 px-1.5 outline-none" />
                                <div className="opacity-30 cursor-pointer hover:opacity-50" onClick={() => handleRemoveCondition(index)}>
                                    <FaTrash />
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
            <Button title="+ Add condition" onClick={handleAddCondition} />
        </div >
    )
}