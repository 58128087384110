import {
  collection,
  getDocs,
  initializeFirestore,
  query,
  where,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyDHAWAbCzLDh6Ix0eJiUqg8kr3Mu3x0QHk",
  authDomain: "onboarding-kit.firebaseapp.com",
  projectId: "onboarding-kit",
  storageBucket: "onboarding-kit.appspot.com",
  messagingSenderId: "230235189495",
  appId: "1:230235189495:web:72298ed092dc79f9afa3f2",
};

const app = initializeApp(firebaseConfig, "secondary");

export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

export function addData(data) {
  db.collection("onboardings")
    .add(data)
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
}

async function getOnboarding(slug) {
  const onboardingsRef = collection(db, "onboardings");
  const q = query(onboardingsRef, where("settings.slug", "==", slug));

  const querySnapshot = await getDocs(q);
  return querySnapshot?.docs[0];
}

export async function getOnboardingId(slug) {
  const doc = await getOnboarding(slug);

  if (!doc) {
    return null;
  }

  return doc.id;
}

export async function isSlugAvailable(id, slug) {
  const doc = await getOnboarding(slug);

  if (doc && doc.id !== id) {
    return false;
  }

  return true;
}

export const storage = getStorage(app);
