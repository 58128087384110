import React from "react"

export default function Picker({ mode, picker_options }) {

    const custom_data = (Array.isArray(picker_options)) ?
        picker_options.map(e => e && typeof e === 'object' && Array.isArray(e.values) ? e.values : null).filter(Boolean) : []


    const data = mode === 'hour' ? [["07", "08", "09", "10", "11", "12", "13"], ["57", "58", "59", "00", "01", "02", "03"]] :
        mode === 'date' ? [["January", "February", "March", "April", "May", "June", "July"], ["11", "12", "13", "14", "15", "16", "17"], ["1992", "1993", "1994", "1995", "1996", "1997", "1998"]] :
            mode === 'year' ? [["1992", "1993", "1994", "1995", "1996", "1997", "1998"]] :
                mode === 'custom' ? custom_data : []

    return (
        <div className="my-auto text-center flex flex-row justify-around text-lg">
            {data.map(tab => (
                <div className="flex flex-col justify-center">
                    {tab.map((e, i) => <span className={`py-1 ${i === Math.floor(tab.length / 2) ? "font-medium opacity-100 text-black" : "opacity-30"}`}>{e}</span>)}
                </div>
            ))}
        </div>
    )
}