import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import CreatePage from '../pages/CreatePage';
import FormPage from '../pages/FormPage';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import React from 'react';
import Spinner from '../components/common/Spinner';
import { useAuthContext } from '../shared/hooks/useAuth';

const sharedRoutes = [
    <Route path="/:slug" element={<FormPage />} key="form" />,
];

export default function Root() {
    const { user, status } = useAuthContext()

    if (status === "loading") {
        return <div className='flex h-screen justify-center items-center text-4xl'><Spinner /></div>;
    }

    return (
        <Router>
            <Routes>
                {user ? (
                    <>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/create/:onboardingId" element={<CreatePage />} />
                        {sharedRoutes}
                    </>
                ) : (
                    <>
                        {sharedRoutes}
                        <Route path="*" element={<LoginPage />} />
                    </>
                )}
            </Routes>
        </Router>
    );
}