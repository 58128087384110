import React from "react";

export default function Dropdown({ title, value, onChange, options }) {
    return (
        <div className="flex flex-row items-center justify-between" >
            {title && <label className="text-sm capitalize" >{title}</label>}
            <select
                value={value}
                onChange={onChange}
                className="w-32 text-sm py-1 px-2 border rounded capitalize"
            >
                {options.map(e => <option key={e} value={e}>{e}</option>)}
            </select >
        </div>
    )
}