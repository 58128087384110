import React, { createContext, useContext, useState } from "react";

const UATrackingContext = createContext();

export const UATrackingProvider = ({ children }) => {
  const [adjustTrackerToken, setAdjustTrackerToken] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [adgroupName, setAdgroupName] = useState("");
  const [adgroupId, setAdgroupId] = useState("");
  const [creativeName, setCreativeName] = useState("");
  const [creativeId, setCreativeId] = useState("");
  const [fbclId, setFbclId] = useState("");
  const [fbpId, setFbpId] = useState("");

  return (
    <UATrackingContext.Provider
      value={{
        adjustTrackerToken,
        setAdjustTrackerToken,
        campaignName,
        setCampaignName,
        campaignId,
        setCampaignId,
        adgroupName,
        setAdgroupName,
        adgroupId,
        setAdgroupId,
        creativeName,
        setCreativeName,
        creativeId,
        setCreativeId,
        fbclId,
        setFbclId,
        fbpId,
        setFbpId,
      }}
    >
      {children}
    </UATrackingContext.Provider>
  );
};

export const useUATrackingContext = () => {
  return useContext(UATrackingContext);
};
