import React from 'react';
import { AuthProvider } from './shared/hooks/useAuth';
import Root from './navigation/Root';

function App() {
    return (
        <AuthProvider>
            <Root />
        </AuthProvider>
    );
}

export default App;
