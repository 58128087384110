import { FaTrash, FaVideo } from "react-icons/fa"; // Changed from FaImage to FaVideo
import React, { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

import { storage } from "../../../shared/helpers/Firebase";
import { useOnboardingContext } from "../../../shared/hooks/useOnboarding";

export default function VideoUploader({ title = "Video", item, video, setVideo }) {
    const { onboardingId } = useOnboardingContext();
    const [preview, setPreview] = useState(null);

    const types = ["video/mp4", "video/webm", "video/quicktime"];

    useEffect(() => {
        getVideoUrl(video);
    }, [video]);

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file && types.includes(file.type)) {
            uploadVideo(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    function uploadVideo(video) {
        if (video) {
            try {
                const videoRef = ref(storage, `onboardings/${onboardingId}/videos/${video.name}`);
                uploadBytes(videoRef, video).then(() => setVideo && setVideo(video.name));
            } catch (error) {
                console.error(error);
            }
        }
    }

    function handleRemoveVideo() {
        setPreview(null);
        setVideo && setVideo("");
    }

    async function getVideoUrl(video) {
        if (video) {
            try {
                const videoRef = ref(storage, `onboardings/${onboardingId}/videos/${video}`);
                const url = await getDownloadURL(videoRef);
                setPreview(url);
            } catch (error) {
                console.error(error);
            }
        }
    }

    return (
        <div>
            {title && <label className="text-sm capitalize">{title}</label>}
            <div className="flex w-full text-sm mt-1 border-dashed rounded-lg items-center justify-center">
                <label
                    className="h-full w-full flex items-center justify-center cursor-pointer"
                    htmlFor="video-upload"
                >
                    {video ? (
                        <div className="flex flex-1 items-center">
                            {
                                preview ?
                                    // Replaced img with video element for preview
                                    <video src={preview} alt="Video preview" className="h-10 aspect-square border rounded object-contain" /> :
                                    <div className="h-10 aspect-square border rounded flex items-center justify-center">
                                        <FaVideo />
                                    </div>
                            }
                            <span className="ml-2 opacity-50">{video}</span>
                        </div>
                    ) : (
                        <div className="flex flex-1 p-2 rounded items-center justify-center border hover:border-gray-300">
                            <div className="text-lg">
                                <FaVideo />
                            </div>
                            <span className="ml-2">{`Upload ${item || "video"}`}</span>
                        </div>
                    )}
                    <input
                        id="video-upload"
                        type="file"
                        className="hidden"
                        onChange={handleVideoChange}
                        accept="video/mp4, video/webm, video/quicktime"
                    />
                </label>
                {video && <div className="opacity-30 text-red-500 cursor-pointer hover:opacity-100" onClick={handleRemoveVideo}><FaTrash /></div>}
            </div>
        </div>
    );
}
