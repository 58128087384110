import React, { useEffect, useState } from "react";
import {
  TrackableEvents,
  sendEvent,
  trackPurchase,
} from "../../../../shared/helpers/TrackingManager";
import {
  getOfferings,
  initializeRevenueCat,
  purchase,
} from "../../../../shared/helpers/RevenueCat";

import ContinueButtonWrapper from "./ContinueButtonWrapper";
import { FaExclamationTriangle } from "react-icons/fa";
import Logo from "../../../common/Logo";
import OfferItem from "../../content/paywall/OfferItem";
import { useOnboardingContext } from "../../../../shared/hooks/useOnboarding";
import { useUserDetailsContext } from "../../../../shared/hooks/useUserDetails";

const CryptoJS = require("crypto-js");

const ERRORS = {
  PAYMENT_ERROR: "There was an error during your payment. Please try again.",
  UNEXPECTED_ERROR: "An unexpected error occured. Please try again.",
};

export default function PaywallWrapper({ isEditMode, style }) {
  const [offerSelected, setOfferSelected] = useState();
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);

  const { email } = useUserDetailsContext();
  const { onboardingId, settings, setSelectedStep } = useOnboardingContext();

  async function handePurchase() {
    setError(null);

    const { rcBillingProduct } = offerSelected;
    const { identifier: productId } = rcBillingProduct;
    const { amount, currency } = rcBillingProduct.currentPrice;
    const revenue = amount / 100;
    
    sendEvent(TrackableEvents.PURCHASE_STARTED, {
      productId: offerSelected.rcBillingProduct.identifier,
    });

    await purchase(
      offerSelected,
      () => {
        trackPurchase(
          settings.adjust_purchase_event_token,
          revenue,
          currency,
          productId
        );

        setSelectedStep({ type: "payment_successful" });
      },
      () => {
        sendEvent(TrackableEvents.PURCHASE_ERROR, {
          error: ERRORS.PAYMENT_ERROR,
        });
        setError(ERRORS.PAYMENT_ERROR);
      }
    );
  }

  async function handleOfferings() {
    const offers = await getOfferings();

    if (offers) {
      if (offers.length === 0) {
        sendEvent(TrackableEvents.PURCHASE_ERROR, {
          error: ERRORS.UNEXPECTED_ERROR,
        });
        setError(ERRORS.UNEXPECTED_ERROR);
      } else {
        setOffers(offers);
        setOfferSelected(offers[0]);
      }
    } else {
      sendEvent(TrackableEvents.PURCHASE_ERROR, {
        error: ERRORS.UNEXPECTED_ERROR,
      });
      setError(ERRORS.UNEXPECTED_ERROR);
    }
  }

  useEffect(() => {
    if (!isEditMode && Object.keys(settings).length > 0) {
      const userId = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
      initializeRevenueCat(settings.revenuecat_api_key, userId);
      handleOfferings();
    }
  }, [isEditMode, settings, email]);

  return (
    <div>
      <div className="my-4">
        <Logo onboardingId={onboardingId} />
      </div>
      <div className="space-y-4">
        <div className="font-semibold text-xl text-center mb-4">
          Choose the best plan for you
        </div>
        {error && (
          <div className="p-4 mb-6 text-red-700 border border-red-200 rounded-lg bg-red-50 space-y-2">
            <div className="flex items-center space-x-1 text-sm">
              <FaExclamationTriangle />
              <h3 className="font-medium">Error</h3>
            </div>
            <div className="text-xs">{error}</div>
          </div>
        )}
        <div className="space-y-2">
          {offers.map((offer) => (
            <OfferItem
              id={offer.rcBillingProduct.identifier}
              key={offer.rcBillingProduct.identifier}
              period={offer.identifier}
              price={offer.rcBillingProduct.currentPrice.formattedPrice}
              isSelected={
                offer.rcBillingProduct.identifier ===
                offerSelected.rcBillingProduct.identifier
              }
              onSelect={() => setOfferSelected(offer)}
            />
          ))}
        </div>
        <ContinueButtonWrapper style={style} handleNextStep={handePurchase} />
      </div>
    </div>
  );
}
