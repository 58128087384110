import React from "react";
import ContentWrapper from "./ContentWrapper";
import TextInput from "../../content/TextInput";

export default function TextInputWrapper({ isEditMode, step, style, userValues, handleUpdateProperty, handleUpdateText }) {
    return (
        <>
            <ContentWrapper
                isEditMode={isEditMode}
                step={step}
                style={style}
                handleUpdateText={(property, value) => handleUpdateText(property, value)}
            />
            <TextInput
                isEditable={isEditMode}
                placeholder={step.placeholder}
                mainColor={style.main_color}
                value={userValues[step?.property]}
                onChange={value => handleUpdateProperty(step?.property, value)} />
        </>
    )
}


