import { FaEye, FaFileDownload, FaQrcode, FaSave } from "react-icons/fa";

import Button from "../../common/Button";
import KovaleeLogo from "../../common/KovaleeLogo";
import Link from "../../common/Link";
import React from "react";
import Status from "../../common/Status";
import TopBarContainer from "../TopBarContainer";
import { useNavigate } from "react-router-dom";
import { useOnboardingConfigContext } from "../../../shared/hooks/useOnboardingConfig";
import { useOnboardingContext } from "../../../shared/hooks/useOnboarding";

export default function TopBar({
  handleDisplayPreviewCode,
  editorMode,
  setEditorMode,
}) {
  const {
    name,
    setName,
    isLoading,
    isSaved,
    handleSave,
    handleDownload,
    errors,
  } = useOnboardingConfigContext();
  const { settings } = useOnboardingContext();
  const navigate = useNavigate();
  const title = isLoading ? "Loading... " : name;

  function handlePressMode(newMode) {
    setEditorMode(newMode);
  }

  function handleBackHome() {
    navigate("/");
  }

  return (
    <TopBarContainer>
      <div className="w-1/3 flex flex-row items-center">
        <KovaleeLogo className="w-28 fill-current text-black mr-4" />
        <div
          className="cursor-pointer text-gray-500 hover:text-black"
          onClick={handleBackHome}
        >
          Home
        </div>
        <div className="ml-3 text-gray-500">/</div>
        <input
          value={title}
          onChange={(e) => setName(e.target.value)}
          style={{ width: "auto" }}
          className="rounded px-3 py-1 flex flex-1 cursor-pointer border border-transparent hover:border-gray-200"
        />
      </div>
      <div className="w-1/3 flex justify-center items-center font-medium">
        <ModeSelectorItem
          title="Content"
          onClick={() => handlePressMode("content")}
          isSelected={editorMode === "content"}
          errors={
            errors &&
            errors.filter((obj) => obj.type === "missing_content").length
          }
        />
        <ModeSelectorItem
          title="Design"
          onClick={() => handlePressMode("design")}
          isSelected={editorMode === "design"}
        />
        <ModeSelectorItem
          title="Settings"
          onClick={() => handlePressMode("settings")}
          isSelected={editorMode === "settings"}
          errors={
            errors &&
            errors.filter((obj) => obj.type === "missing_setting").length
          }
        />
      </div>
      <div className="w-1/3 flex justify-end">
        <div className="inline-flex items-center space-x-1">
          <div className="me-2">
            {isLoading ? (
              <Status text={"Loading..."} />
            ) : isSaved ? (
              <Status type="success" text={"Saved"} />
            ) : (
              <Status type="alert" text={"Unsaved"} />
            )}
          </div>

          <Button icon={<FaSave />} title="Save" onClick={handleSave} />
          <Button
            icon={<FaFileDownload />}
            title="Download"
            onClick={handleDownload}
          />
          <Link
            icon={<FaEye />}
            title="Preview"
            url={`${window.location.origin}/${settings.slug}`}
            openNewTab={true}
          />
          <Button icon={<FaQrcode />} onClick={handleDisplayPreviewCode} />
        </div>
      </div>
    </TopBarContainer>
  );
}

function ModeSelectorItem({
  title = "",
  isSelected = false,
  errors = 0,
  onClick = () => {},
}) {
  const selectedModeClass =
    "h-[46px] flex items-center mx-2 border-black border-b-2 cursor-pointer capitalize transition-all";
  const unselectedModeClass =
    "h-[46px] flex items-center mx-2 border-white border-b-2 cursor-pointer capitalize transition-all text-gray-500 hover:border-gray-500";

  return (
    <div className="relative">
      <div
        onClick={onClick}
        className={isSelected ? selectedModeClass : unselectedModeClass}
      >
        {title}
      </div>
      {errors > 0 && (
        <div className="absolute top-1.5 -right-1 h-3 w-3 bg-red-500 rounded-full text-[9px] flex justify-center items-center font-bold text-white">
          {errors}
        </div>
      )}
    </div>
  );
}
