import React, { createContext, useContext, useState } from "react";

const UserDetailsContext = createContext();

export const UserDetailsProvider = ({ children }) => {
  const [email, setEmail] = useState("");

  return (
    <UserDetailsContext.Provider
      value={{
        email,
        setEmail,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export const useUserDetailsContext = () => {
  return useContext(UserDetailsContext);
};
