import React from "react";

export default function Switch({ title, value = false, onChange }) {
    return (
        <div className="flex direction-row justify-between" >
            {title && <label className="text-sm capitalize">{title}</label>}
            <label className="relative inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={value}
                    onChange={onChange} />
                <div className="w-[30px] h-4 bg-gray-200 rounded-full 
                peer-checked:after:translate-x-full peer-checked:bg-gray-600
                after:h-3.5 after:w-3.5 after:absolute after:top-[3px] after:left-[1px] after:bg-white after:rounded-full after:transition-all" />
            </label>
        </div>
    )
}