import React, { Children, useState } from "react";
import { FaChevronUp, FaChevronDown, FaRulerCombined } from "react-icons/fa";

export default function SettingsContainer({ title, icon, children }) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="border rounded-lg bg-white p-2">
            {title &&
                <div className={`flex justify-between items-center cursor-pointer z-50 ${isOpen && "pb-2"}`} onClick={() => setIsOpen(!isOpen)}>
                    <div className="inline-flex items-center">
                        <div className="mr-2 p-auto border border-gray-100 text-gray-500 p-1.5 rounded flex items-center justify-center text-xs">{icon || <FaRulerCombined />}</div>
                        <div className="text-sm font-semibold">{title || ""}</div>
                    </div>
                    <div className="text-xs">{isOpen ? <FaChevronDown /> : <FaChevronUp />}</div>
                </div>
            }
            <div className="space-y-2">
                {
                    (!title || isOpen) && Children.map(children, (child) =>
                        <div className="p-2 rounded border border-gray-100 bg-gray-50">
                            {child}
                        </div>
                    )
                }
            </div>
        </div>
    )
}