import React, { useEffect, useState } from "react";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";

import KovaleeLogo from "../components/common/KovaleeLogo";
import OnboardingItem from "../components/home/OnboardingItem";
import Spinner from "../components/common/Spinner";
import TopBarContainer from "../components/layout/TopBarContainer";
import { db } from "../shared/helpers/Firebase";
import { initialStyleData } from "../data/InitialStyleData";
import { useAuthContext } from "../shared/hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate = useNavigate();
  const [onboardings, setOnboardings] = useState([]);
  const [filterMode, setFilterMode] = useState("all");
  const [orderMode, setOrderMode] = useState("updated");
  const { user, logout } = useAuthContext();

  const filteredData = (data) => {
    if (filterMode === "mine") {
      return data.filter((e) => e.creator === user?.email);
    } else {
      return data;
    }
  };

  const orderedData = (data) => {
    if (orderMode === "updated") {
      return data.sort((a, b) => b.updated?.toDate() - a.updated?.toDate());
    } else if (orderMode === "created") {
      return data.sort((a, b) => b.created?.toDate() - a.created?.toDate());
    } else if ("alphabetical") {
      return data.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else {
      return data;
    }
  };

  const fetchOnboardings = async () => {
    await getDocs(collection(db, "onboardings")).then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setOnboardings(data);
      console.log(data);
    });
  };

  useEffect(() => {
    fetchOnboardings();
  }, []);

  const handleNewOnboarding = async (e) => {
    e.preventDefault();
    createOnboarding({ style: initialStyleData });
  };

  async function createOnboarding(data) {
    try {
      const docRef = await addDoc(collection(db, "onboardings"), {
        ...data,
        name: data?.name || "New onboarding",
        creator: user?.email,
        created: Timestamp.now(),
        updated: Timestamp.now(),
      });
      navigate("/create/" + docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const handleUploadOnboarding = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target.result;
      try {
        const parsedData = JSON.parse(content);
        if (parsedData.style && parsedData.steps) {
          createOnboarding({
            style: parsedData.style,
            steps: parsedData.steps,
          });
        } else {
          alert("Invalid JSON format!");
        }
      } catch (error) {
        alert("Error parsing JSON!");
      }
    };

    reader.readAsText(file);
  };

  function handleOpenOnboarding(onboardingId) {
    navigate("/create/" + onboardingId);
  }

  async function handleDeleteOnboarding(onboardingId) {
    await deleteDoc(doc(db, "onboardings", onboardingId)).then(() =>
      fetchOnboardings()
    );
  }

  async function handleDuplicateOnboarding(onboardingId) {
    try {
      const docRef = doc(db, "onboardings", onboardingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        data.name = data.name + " (copy)";
        createOnboarding(data);
      }
    } catch (error) {
      console.error("Erreur lors de la duplication du document", error);
    }
  }

  return (
    <div className="h-screen">
      <TopBarContainer>
        <div className="flex items-center justify-between flex-1">
          <KovaleeLogo className="w-28 fill-current text-black" />
          <div className="font-semibold justify-center">OnboardingKit Configurator</div>
          <div className="flex justify-end">
            <div>
              <div className="text-xs text-end">{user?.email}</div>
              <div
                className="text-xs text-end cursor-pointer text-gray-500 hover:text-black"
                onClick={() => logout()}
              >
                Logout
              </div>
            </div>
            <div className="h-8 w-8 ml-2 bg-primary rounded-full justify-center items-center flex text-white font-bold uppercase">
              {user?.email?.charAt(0)}
            </div>
          </div>
        </div>
      </TopBarContainer>

      <div className="flex flex-1 column h-[calc(100%-48px)] p-3 bg-gray-50 overflow-y-auto">
        <div className="flex-1 mt-3">
          <div className="flex flex-1 flex-row items-center">
            <div className="w-1/2">
              <select
                className="p-2 bg-gray-200 rounded cursor-pointer text-sm"
                onChange={(e) => setOrderMode(e.target.value)}
              >
                <option value="updated">Last updated</option>
                <option value="created">Date created</option>
                <option value="alphabetical">Alphabetical</option>
              </select>
              <select
                className="ml-2 p-2 bg-gray-200 rounded cursor-pointer text-sm"
                onChange={(e) => setFilterMode(e.target.value)}
              >
                <option value="all">All onboardings</option>
                <option value="mine">My onboardings</option>
              </select>
            </div>

            <div className="w-1/2 flex justify-end">
              <div>
                <input
                  type="file"
                  accept=".json"
                  onChange={handleUploadOnboarding}
                  className="absolute opacity-0 w-0 h-0"
                  id="fileInput"
                />
                <button
                  onClick={() => document.getElementById("fileInput").click()}
                  className="px-3 py-2 mr-2 bg-transparent rounded text-sm text-primary font-semibold border border-primary cursor-pointer hover:bg-opacity-80"
                >
                  Upload config file
                </button>
              </div>
              <button
                className="px-3 py-2 mr-2 bg-primary rounded text-sm text-white font-semibold border border-primary cursor-pointer hover:bg-opacity-80"
                onClick={handleNewOnboarding}
              >
                + New onboarding
              </button>
            </div>
          </div>

          <div className="my-4 mx-1 border-b border-gray-100" />

          <div className="flex flex-row px-3 mr-11">
            <div className="w-2/6 text-xs">Onboarding</div>
            <div className="w-1/6 text-xs">Creator</div>
            <div className="w-1/6 text-xs">Style</div>
            <div className="w-1/6 text-xs">Steps</div>
            <div className="w-1/6 text-xs">Updated</div>
          </div>
          {onboardings && onboardings.length > 0 ? (
            orderedData(filteredData(onboardings)).map((item) => (
              <OnboardingItem
                item={item}
                handleOpenOnboarding={handleOpenOnboarding}
                handleDuplicateOnboarding={handleDuplicateOnboarding}
                handleDeleteOnboarding={handleDeleteOnboarding}
              />
            ))
          ) : (
            <div className="flex h-full justify-center items-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
